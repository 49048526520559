import { call, take } from 'redux-saga/effects';
import { whiteboardKeyDecrypted } from 'features/e2ee/actions';
import { AesCm256EncryptionKey } from 'features/e2ee/AesCm256EncryptionKey';
import { E2EEManager } from 'features/e2ee/E2EEManager';

export function* waitForWhiteboardKeyDecryptionSaga(expectedId: string, roomId: string) {
  // Try to retrieve the whiteboard key immediately
  const existingKey: AesCm256EncryptionKey | undefined = yield call(
    E2EEManager.whiteboard.getKey,
    expectedId,
    roomId
  );

  if (existingKey) {
    return existingKey;
  }

  // wait until the expected ID is decrypted
  while (true) {
    const action: ReturnType<typeof whiteboardKeyDecrypted> = yield take(whiteboardKeyDecrypted);

    if (action.payload.id === expectedId) {
      const newKey: AesCm256EncryptionKey | undefined = yield call(
        E2EEManager.whiteboard.getKey,
        expectedId,
        roomId
      );

      if (!newKey) {
        throw new Error(
          `Failed to retrieve the whiteboard key for id=${expectedId}, roomId=${roomId}`
        );
      }

      return newKey;
    }
  }
}
