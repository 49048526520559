import { createAsyncThunk } from '@reduxjs/toolkit';
import { loginRoomService } from 'features/room/services';
import { selectRoomToken, selectRoomUrlId } from 'features/room/roomSlice';
import { PrejoinResponse } from 'features/room/types';
import { selectSDKInitState } from 'features/sdk/sdkStateSlice';
import { RootState } from 'store/store';
import { RoomLoginFormValues } from 'features/room/components/RoomLogin';
import { isAPIError } from 'utils/types';
import { serializeError } from 'serialize-error';
import { UserName } from 'features/users/types';

export const authorizeUser = createAsyncThunk<
  PrejoinResponse,
  UserName,
  {
    state: RootState;
    rejectValue: RoomLoginFormValues;
    fulfilledMeta: { name: string; initials?: string };
  }
>(
  'room/authorizeUser',
  async (name, { getState, rejectWithValue, fulfillWithValue }) => {
    const token = selectRoomToken(getState());
    const roomUrlId = selectRoomUrlId(getState());
    const sdkInitState = selectSDKInitState(getState());
    const initials = token.parsedData?.initials || sdkInitState.initials;

    const username = name || token.parsedData?.u || sdkInitState.username;

    try {
      const response = await loginRoomService(roomUrlId, username, initials, token.jwt);

      if (!username) {
        throw new Error('Username is undefined');
      }

      return fulfillWithValue(response, {
        name: username,
        initials,
      });
    } catch (error) {
      if (!isAPIError(error)) {
        throw error;
      }

      if (error.details) {
        // manage login field name on our side to avoid naming collisions
        if (error.details.name) {
          error.details.login = error.details.name;
          delete error.details.name;
        }

        return rejectWithValue(error.details);
      }

      throw error;
    }
  },
  {
    serializeError,
  }
);
