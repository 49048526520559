import { arrayBufferToBase64 } from 'features/e2ee/utils/base64';
import { encryptString } from 'features/e2ee/utils/encryptString';

export const e2eeEncrypt = async (data: string, key: CryptoKey) => {
  const { cipher, iv } = await encryptString(data, key);

  return JSON.stringify({
    cipher: arrayBufferToBase64(cipher),
    iv: arrayBufferToBase64(iv),
  });
};
