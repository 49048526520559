import { splashScreenOpened } from 'features/breakout-rooms/breakoutRoomsSlice';
import { normalizeBreakoutRoomsSaga } from 'features/breakout-rooms/sagas/normalizeBreakoutRoomsSaga';
import { call, put, select } from 'redux-saga/effects';
import { signalingBreakoutRoomsReceived } from 'features/breakout-rooms/actions';
import { selectE2eeEnabled } from 'features/e2ee/e2eeSlice';
import { NormalizedBreakoutRoomsConfig } from 'features/breakout-rooms/types';
import { computeBreakoutUserKeysSaga } from 'features/e2ee/sagas/computeBreakoutUserKeysSaga';

export function* onBreakoutRoomsReceivedSaga(
  action: ReturnType<typeof signalingBreakoutRoomsReceived>
) {
  const config: NormalizedBreakoutRoomsConfig = yield call(normalizeBreakoutRoomsSaga, {
    rooms: action.payload.rooms,
    unassignedUsers: action.payload.unassignedUsers,
  });

  const e2eeEnabled: boolean = yield select(selectE2eeEnabled);
  if (e2eeEnabled) {
    yield call(computeBreakoutUserKeysSaga, config);
  }

  if (action.payload.started) {
    yield put(splashScreenOpened);
  }
}
