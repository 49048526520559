import { AesCm256EncryptionKey } from 'features/e2ee/AesCm256EncryptionKey';

export class E2EEWhiteboardManager {
  private keys = new Map<string, AesCm256EncryptionKey>();

  private makeId(whiteboardId: string, roomId: string) {
    if (!whiteboardId) {
      throw new Error('Invalid whiteboardId provided for building whiteboard E2EE id');
    }

    if (!roomId) {
      throw new Error('Invalid roomId provided for building whiteboard E2EE id');
    }

    return JSON.stringify({ whiteboardId, roomId });
  }

  private parseId(id: string): { whiteboardId: string; roomId: string } {
    try {
      return JSON.parse(id);
    } catch {
      throw new Error('Invalid E2EE whiteboard id format');
    }
  }

  generateKey = async (whiteboardId: string, roomId: string) => {
    const id = this.makeId(whiteboardId, roomId);

    const key = new AesCm256EncryptionKey();
    await key.generate({ hkdf: true });

    this.keys.set(id, key);

    return key;
  };

  getKey = (whiteboardId: string, roomId: string) => {
    const id = this.makeId(whiteboardId, roomId);

    return this.keys.get(id);
  };

  importKey = async (raw: ArrayBuffer, whiteboardId: string, roomId: string) => {
    const id = this.makeId(whiteboardId, roomId);

    const key = await AesCm256EncryptionKey.create(raw);

    this.keys.set(id, key);
  };
}
