import { e2eeDecrypt } from 'features/e2ee/utils/e2eeDecrypt';
import { fileToBase64, urlToArrayBuffer } from 'utils/file';
import { decryptFile } from 'features/e2ee/utils/decryptFile';
import { TLRecord } from '@digitalsamba/tldraw';

export const decryptWBRecords = async (encryptedRecords: string[], decryptionKey: CryptoKey) => {
  const decryptionPromises = encryptedRecords.map<Promise<TLRecord>>(async (row: string) => {
    const decryptedData = await e2eeDecrypt(row, decryptionKey);
    const record = JSON.parse(decryptedData);

    if (record.typeName === 'asset') {
      const { src } = record.props;

      const encryptedFile = await urlToArrayBuffer(src);
      if (encryptedFile) {
        const decryptedBlob = await decryptFile(encryptedFile, decryptionKey);

        // preserve the original url with the encrypted file
        record.props.src = await fileToBase64(decryptedBlob);
        record.meta = {
          ...record.meta,
          src,
        };
      }
    }

    return record;
  });

  return Promise.all(decryptionPromises);
};
