import { selectPreserveAspectRatio } from 'features/streaming/streamingSlice';
import { call, put, select, take } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { SignalingSocket } from 'services/signaling';
import { signalingRoomJoined } from 'features/room/actions';
import { roomError, roomJoinStarted } from 'features/room/roomSlice';
import * as Sentry from '@sentry/react';
import { selectE2eeEnabled } from 'features/e2ee/e2eeSlice';
import { E2EEManager } from 'features/e2ee/E2EEManager';
import { JoinRoomData, RoomJoinedPayload } from 'features/room/types';
import { onRoomJoinConfigSaga } from 'features/room/sagas/onRoomJoinConfigSaga';

export function* joinRoomSaga() {
  try {
    yield put(roomJoinStarted());

    const e2eeEnabled: boolean = yield select(selectE2eeEnabled);

    const joinPayload: JoinRoomData = {};

    if (e2eeEnabled) {
      joinPayload.e2eePublicKey = yield call(E2EEManager.exportPublicKey);
    }

    const preserveAspectRatio: boolean = yield select(selectPreserveAspectRatio);
    if (preserveAspectRatio) {
      joinPayload.keepAspectRatio = preserveAspectRatio;
    }

    yield call(SignalingSocket.send, { event: 'joinRoom', data: joinPayload });

    const action: PayloadAction<RoomJoinedPayload> = yield take(signalingRoomJoined);

    yield call(onRoomJoinConfigSaga, action.payload);
  } catch (error) {
    yield call(Sentry.captureException, error);
    yield put(roomError({ global: true }));
  }
}
