import { usePiPWindow } from 'components/PiP/PiPProvider';
import PiPWindow from 'components/PiP/PiPWindow';
import { PipLayout } from 'features/layout/features/pictureInPicture/PipLayout';
import TopbarControl from 'features/room/components/topbar/TopbarControl';
import debounce from 'lodash.debounce';
import throttle from 'lodash.throttle';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

function useResizablePipWindow(pipWindow: Window | null) {
  const [size, setSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (!pipWindow) {
      return;
    }

    const handleResize = throttle(
      (e) => {
        if (!e.target) {
          return;
        }

        // if ((e.target as Window).innerWidth < 400) {
        //   e.target.resizeTo(400, e.target.innerHeight);
        // }

        setSize({
          width: (e.target as Window).innerWidth,
          height: (e.target as Window).innerHeight,
        });
      },
      1000,
      { leading: true, trailing: false }
    );

    const handleResizeEnd = debounce(() => {
      setSize({
        width: pipWindow.innerWidth,
        height: pipWindow.innerHeight,
      });
    }, 100);

    pipWindow.addEventListener('resize', handleResize);
    pipWindow.addEventListener('resize', handleResizeEnd);

    // eslint-disable-next-line consistent-return
    return () => {
      pipWindow.removeEventListener('resize', handleResize);
      pipWindow.removeEventListener('resize', handleResizeEnd);
    };
  }, [pipWindow]);

  return size;
}

export const PiPControl = () => {
  const { pipWindow, requestPipWindow, closePipWindow } = usePiPWindow();
  const { t } = useTranslation('room');

  const handleToggle = () => {
    if (pipWindow === null) {
      requestPipWindow(500, 500);
    } else {
      closePipWindow();
    }
  };

  const size = useResizablePipWindow(pipWindow);

  const tooltipText = pipWindow === null ? t('topbar.enter_pip') : t('topbar.exit_pip');

  const buttonText = pipWindow === null ? t('topbar.enter_pip') : t('topbar.exit_pip');

  const iconName = pipWindow === null ? 'pip' : 'pip-exit';

  return (
    <>
      <TopbarControl
        tooltipText={tooltipText}
        onToggle={handleToggle}
        buttonText={buttonText}
        iconName={iconName}
        data-testid="pip-control"
      />

      {pipWindow && (
        <PiPWindow pipWindow={pipWindow}>
          <div className="pipRoot">
            <PipLayout {...size} />
          </div>
        </PiPWindow>
      )}
    </>
  );
};
