import { call, select } from 'redux-saga/effects';
import { E2EEManager } from 'features/e2ee/E2EEManager';
import { selectE2eeEnabled } from 'features/e2ee/e2eeSlice';
import { selectRoomId } from 'features/room/roomSlice';
import { signalingEncryptionKeyShared } from 'features/e2ee/actions';

export function* onEncryptionKeySharedSaga(
  action: ReturnType<typeof signalingEncryptionKeyShared>
) {
  const e2eeEnabled: boolean = yield select(selectE2eeEnabled);
  if (e2eeEnabled) {
    const { initiator, streamKey, applicationKey, name } = action.payload;
    const roomId: string = yield select(selectRoomId);

    yield call(E2EEManager.decryptRemoteData, initiator.id, roomId, {
      applicationKey,
      streamKey,
      name,
    });
  }
}
