import { call, select } from 'redux-saga/effects';
import { E2EEManager } from 'features/e2ee/E2EEManager';
import { selectE2eeEnabled } from 'features/e2ee/e2eeSlice';
import { signalingEncryptedNameShared } from 'features/e2ee/actions';
import { selectRoomId } from 'features/room/roomSlice';

export function* onEncryptedNameSharedSaga(
  action: ReturnType<typeof signalingEncryptedNameShared>
) {
  const e2eeEnabled: boolean = yield select(selectE2eeEnabled);
  if (e2eeEnabled) {
    const { initiator, name, initiatorRoomId } = action.payload;
    const mainRoomId: string = yield select(selectRoomId);
    const roomId = initiatorRoomId || mainRoomId;

    yield call(E2EEManager.decryptRemoteData, initiator.id, roomId, { name });
  }
}
