import { call, fork, select } from 'redux-saga/effects';
import { selectE2eeEnabled } from 'features/e2ee/e2eeSlice';
import { signalingWhiteboardKeyRequested } from 'features/e2ee/actions';
import { selectRoomId } from 'features/room/roomSlice';
import { exchangeWhiteboardKeySaga } from 'features/e2ee/sagas/exchangeWhiteboardKeySaga';
import { User } from 'features/users/types';
import { selectUserById } from 'features/users/usersSlice';
import { AesCm256EncryptionKey } from 'features/e2ee/AesCm256EncryptionKey';
import { E2EEManager } from 'features/e2ee/E2EEManager';

export function* onWhiteboardKeyRequestedSaga(
  action: ReturnType<typeof signalingWhiteboardKeyRequested>
) {
  const e2eeEnabled: boolean = yield select(selectE2eeEnabled);
  if (e2eeEnabled) {
    const roomId: string = yield select(selectRoomId);
    const { userId, id } = action.payload;

    const whiteboardKey: AesCm256EncryptionKey | undefined = yield call(
      E2EEManager.whiteboard.getKey,
      id,
      roomId
    );

    // ignore if we don't have requested key
    if (!whiteboardKey) {
      return;
    }

    // don't send the key to left user
    const user: User = yield select(selectUserById, userId);
    if (!user) {
      return;
    }

    yield fork(exchangeWhiteboardKeySaga, id, {
      id: userId,
      roomId,
      e2eePublicKey: user.e2eePublicKey!,
    });
  }
}
