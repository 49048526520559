import { call, select } from 'redux-saga/effects';
import { E2EEManager } from 'features/e2ee/E2EEManager';
import { User } from 'features/users/types';
import { SignalingSocket } from 'services/signaling';
import { e2eeEncrypt } from 'features/e2ee/utils/e2eeEncrypt';
import { selectLocalUser } from 'features/users/usersSlice';
import { ExchangeKeyParams } from 'features/e2ee/types';
import { selectIsRecorderSession } from 'features/recorder/recorderSlice';

export function* exchangeEncryptionKeysSaga(params: ExchangeKeyParams) {
  const sharedSecret: CryptoKey = yield call(
    E2EEManager.exchangeEncryptionKey,
    params.id,
    params.roomId,
    params.e2eePublicKey
  );

  const exportedStreamKey: string = yield call(E2EEManager.streamKey.export);
  const encryptedStreamKey: string = yield call(e2eeEncrypt, exportedStreamKey, sharedSecret);

  const isRecorderSession: boolean = yield select(selectIsRecorderSession);

  // send only stream key from the recorder
  if (isRecorderSession) {
    yield call(SignalingSocket.send, {
      event: 'shareEncryptionKey',
      data: {
        streamKey: encryptedStreamKey,
        id: params.id,
      },
    });

    return;
  }

  const exportedApplicationKey: string = yield call(E2EEManager.applicationKey.export);
  const encryptedApplicationKey: string = yield call(
    e2eeEncrypt,
    exportedApplicationKey,
    sharedSecret
  );

  const localUser: User = yield select(selectLocalUser);
  if (!localUser.name) {
    throw new Error('Cannot encrypt name. The name is undefined.');
  }

  const encryptedUsername: string = yield call(e2eeEncrypt, localUser.name, sharedSecret);

  yield call(SignalingSocket.send, {
    event: 'shareEncryptionKey',
    data: {
      streamKey: encryptedStreamKey,
      applicationKey: encryptedApplicationKey,
      name: encryptedUsername,
      id: params.id,
    },
  });
}
