import { memo, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { usePermissions } from 'features/permissions/usePermissions';
import { PermissionTypes } from 'features/permissions/types';

import { localAudioToggled, localVideoToggled } from 'features/streaming/actions';
import { selectDeviceInfo } from 'features/application/applicationSlice';

const RoomKeyboardShortcuts = memo(() => {
  const dispatch = useAppDispatch();
  const { hasPermissions } = usePermissions();
  const allowBroadcast = hasPermissions(PermissionTypes.broadcast);

  const deviceInfo = useAppSelector(selectDeviceInfo);
  const isMacOS = deviceInfo.os.name === 'Mac OS';

  useEffect(() => {
    // Sets up keyboard shortcuts for toggling audio (A) and video (V):
    // - macOS: Shift + Cmd + A / Shift + Cmd + V
    // - Windows: Shift + Alt + A / Shift + Alt + V

    if (!allowBroadcast) {
      return;
    }

    const handleKeydown = (event: KeyboardEvent) => {
      const key = event.key.toLowerCase();

      const isMacCombination = isMacOS && event.metaKey && event.shiftKey;
      const isWindowsCombination = !isMacOS && event.altKey && event.shiftKey;

      if ((isMacCombination || isWindowsCombination) && key === 'a') {
        dispatch(localAudioToggled());
        event.preventDefault();
      }

      if ((isMacCombination || isWindowsCombination) && key === 'v') {
        dispatch(localVideoToggled());
        event.preventDefault();
      }
    };

    window.addEventListener('keydown', handleKeydown);

    return () => {
      window.removeEventListener('keydown', handleKeydown);
    };
  }, [dispatch, allowBroadcast, isMacOS]);

  return null;
});

export default RoomKeyboardShortcuts;
