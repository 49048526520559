import { AesCm256EncryptionKey } from 'features/e2ee/AesCm256EncryptionKey';
import { call, select } from 'redux-saga/effects';
import { selectRoomId } from 'features/room/roomSlice';
import * as Sentry from '@sentry/react';
import { logger } from 'utils/logger';
import { e2eeDecrypt } from 'features/e2ee/utils/e2eeDecrypt';
import { waitForWhiteboardKeyDecryptionSaga } from 'features/layout/sagas/waitForTheWhiteboardKeyDecryptionSaga';

export function* decryptWhiteboardCursorPositionChangeSaga(id: string, coords: string) {
  const roomId: string = yield select(selectRoomId);

  const whiteboardKey: AesCm256EncryptionKey = yield call(
    waitForWhiteboardKeyDecryptionSaga,
    id,
    roomId
  );

  const decryptionKey = whiteboardKey.encryptionKey;
  if (!decryptionKey) {
    const errorMessage = `Cannot decrypt whiteboard pointer update for id=${id}, roomId=${whiteboardKey}. Decryption key is undefined.`;
    Sentry.captureException(errorMessage);

    return;
  }

  try {
    const decryptedCoords: string = yield call(e2eeDecrypt, coords, decryptionKey);

    return JSON.parse(decryptedCoords);
  } catch (error) {
    const errorMessage = `Cannot decrypt whiteboard cursor position change for id=${id}, roomId=${roomId}`;
    logger.remote({ system: true, capture: 'e2ee' }).error(errorMessage);

    throw error;
  }
}
