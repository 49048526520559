import { useAppDispatch } from 'store/hooks';
import { closeModal } from 'features/modal/modalSlice';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogTitle } from 'components/Dialog';
import { DialogContent, DialogContentText } from '@mui/material';
import Button from '@mui/material/Button';
import React, { useEffect, useId } from 'react';
import { PermissionTypes } from 'features/permissions/types';
import { usePermissions } from 'features/permissions/usePermissions';
import { useFeatureFlag } from 'features/feature-set/useFeatureFlag';
import { DialogActions } from '../../../components/Dialog';

const ContentLibraryE2eeWBWarningModal = () => {
  const { t } = useTranslation('modals');
  const dispatch = useAppDispatch();

  const prefix = useId();

  const { hasPermissions } = usePermissions();

  const isContentLibraryEnabled = useFeatureFlag('contentLibrary');
  const canPresentContentLibrary = hasPermissions(PermissionTypes.presentContentLibrary);

  const isContentLibraryFeatureAvailable = isContentLibraryEnabled && canPresentContentLibrary;

  const modalShouldBeClosed = !isContentLibraryFeatureAvailable;

  useEffect(() => {
    if (modalShouldBeClosed) {
      dispatch(closeModal('contentLibraryE2EEWhiteboardWarning'));
    }
  }, [modalShouldBeClosed, dispatch]);

  const handleOk = () => dispatch(closeModal('contentLibraryE2EEWhiteboardWarning'));

  return (
    <Dialog
      open={!modalShouldBeClosed}
      aria-labelledby={`${prefix}-title`}
      aria-describedby={`${prefix}-description`}
      onClose={handleOk}
      maxWidth="xs"
    >
      <DialogTitle id={`${prefix}-title`} onClose={handleOk}>
        {t('content_library.e2ee_whiteboard_warning.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id={`${prefix}-description`}>
          {t('content_library.e2ee_whiteboard_warning.description')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOk}>
          {t('content_library.e2ee_whiteboard_warning.btn_ok_label')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ContentLibraryE2eeWBWarningModal;
