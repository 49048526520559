import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TopbarControl from 'features/room/components/topbar/TopbarControl';

const FullscreenModeControl = () => {
  const { t } = useTranslation('room');
  const [isFullscreen, setIsFullscreen] = useState(() => Boolean(document.fullscreenElement));

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(Boolean(document.fullscreenElement));
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  const handleToggle = () => {
    if (isFullscreen) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
  };

  const tooltipText = isFullscreen
    ? t('topbar.fullscreeen.exit_tooltip')
    : t('topbar.fullscreeen.enter_tooltip');

  const buttonText = isFullscreen
    ? t('topbar.fullscreeen.btn_exit_label')
    : t('topbar.fullscreeen.btn_enter_label');

  const iconName = isFullscreen ? 'exit-fullscreen1' : 'enter-fullscreen';

  return (
    <TopbarControl
      tooltipText={tooltipText}
      onToggle={handleToggle}
      buttonText={buttonText}
      iconName={iconName}
      data-testid="fullscreen-control"
    />
  );
};

export default FullscreenModeControl;
