import { activeSpeakerReset } from 'features/streaming/streamingSlice';
import { captionsToggled } from 'features/transcripts/slices/captions/captionsSlice';
import { chatMessagesReset } from 'features/chat/chatSlice';
import { streamMinimized } from 'features/layout/actions';
import {
  gridPanelClosed,
  selectMaximizedStream,
} from 'features/layout/features/content/contentSlice';
import { SourceDetails } from 'features/layout/types';
import { closeModal, selectActiveModal } from 'features/modal/modalSlice';
import { ModalName } from 'features/modal/types';
import { clearAllNotificationsSaga } from 'features/notifications/sagas/clearAllNotificationsSaga';
import { hasPermissionsSaga } from 'features/permissions/sagas/hasPermissionsSaga';
import { PermissionTypes } from 'features/permissions/types';
import { broadcastSetupEnded, selectIsBroadcastSetupActive } from 'features/room/roomSlice';
import { userListReset } from 'features/users/usersSlice';
import { selectGridPanelOpen } from 'features/whiteboard/whiteboardSlice';
import { call, put, select } from 'redux-saga/effects';
import { RTCClient } from 'utils/webrtc';
import {
  fileClosed,
  selectContentLibraryOpenedFile,
} from 'features/content-library/contentLibrarySlice';
import { ContentLibraryFileEntry } from 'features/content-library/types';
import { selectE2eeEnabled } from 'features/e2ee/e2eeSlice';
import { SFrameManager } from 'features/e2ee/SFrameManager';
import { cleanBreakoutRooms } from 'features/breakout-rooms/breakoutRoomsSlice';

// fire to set up movement between breakout/main rooms;
export function* prepareRoomMovementSaga() {
  yield put(cleanBreakoutRooms());

  const isBroadcastSetupActive: boolean = yield select(selectIsBroadcastSetupActive);
  if (isBroadcastSetupActive) {
    yield put(broadcastSetupEnded());
  }

  const gridPanelOpen: boolean = yield select(selectGridPanelOpen);
  if (gridPanelOpen) {
    yield put(gridPanelClosed());
  }

  const openedFile: ContentLibraryFileEntry | undefined = yield select(
    selectContentLibraryOpenedFile
  );
  if (openedFile) {
    yield put(fileClosed());
  }

  yield put(captionsToggled(false));

  const maximizedStream: SourceDetails = yield select(selectMaximizedStream);

  if (maximizedStream) {
    yield put(streamMinimized());
  }

  yield put(chatMessagesReset());
  yield put(activeSpeakerReset());

  const activeModal: ModalName | null = yield select(selectActiveModal);
  if (activeModal) {
    yield put(closeModal(activeModal));
  }

  const preserveMediaStates: boolean = yield call(hasPermissionsSaga, PermissionTypes.broadcast);

  yield call(RTCClient.reset, preserveMediaStates);
  const e2eeEnabled: boolean = yield select(selectE2eeEnabled);
  if (e2eeEnabled) {
    yield call(SFrameManager.cleanup);
  }

  yield put(userListReset());

  yield call(clearAllNotificationsSaga);
}
