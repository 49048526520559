import { call, select } from 'redux-saga/effects';
import { E2EEManager } from 'features/e2ee/E2EEManager';
import { selectE2eeEnabled } from 'features/e2ee/e2eeSlice';
import * as Sentry from '@sentry/react';
import { logger } from 'utils/logger';
import { signalingBreakoutUserJoined } from 'features/breakout-rooms/actions';
import { selectRoomId } from 'features/room/roomSlice';

export function* onBreakoutUserJoinedSaga(action: ReturnType<typeof signalingBreakoutUserJoined>) {
  try {
    const e2eeEnabled: boolean = yield select(selectE2eeEnabled);
    if (e2eeEnabled) {
      const mainRoomId: string = yield select(selectRoomId);

      const { id, user } = action.payload;
      const roomId = id || mainRoomId;

      yield call(E2EEManager.exchangeEncryptionKey, user.id, roomId, user.e2eePublicKey!);
    }
  } catch (error) {
    Sentry.captureException(error);
    logger
      .remote({ tier: 1 })
      .error(`Cannot handle breakout user entry id=${action.payload.id}`, error);
  }
}
