import { selectMaximizedStream } from 'features/layout/features/content/contentSlice';
import {
  selectHistoricalSpeakers,
  selectOrderedStreams,
  speakersUpdated,
} from 'features/layout/features/order/orderSlice';
import { put, select } from 'redux-saga/effects';
import { orderChanged, streamMinimized } from 'features/layout/actions';
import { userLeft } from 'features/users/usersSlice';
import { SourceDetails } from 'features/layout/types';

export function* onBroadcastEndSaga(action: ReturnType<typeof userLeft>) {
  const streams: SourceDetails[] = yield select(selectOrderedStreams);
  const speakers: SourceDetails[] = yield select(selectHistoricalSpeakers);
  const maximizedStream: SourceDetails = yield select(selectMaximizedStream);

  const userIdsSet = new Set(action.payload.map(({ userId }) => userId));

  const activeStream = streams.find((stream) => userIdsSet.has(stream.userId));
  if (!activeStream) {
    return;
  }

  const updatedStreams = streams.filter((stream) => !userIdsSet.has(stream.userId));
  const updatedSpeakers = speakers.filter((stream) => !userIdsSet.has(stream.userId));

  yield put(orderChanged(updatedStreams));
  yield put(speakersUpdated(updatedSpeakers));

  if (userIdsSet.has(maximizedStream?.userId)) {
    yield put(streamMinimized());
  }
}
