import { BreakoutRoomsStatus, NormalizedBreakoutRoomsConfig } from 'features/breakout-rooms/types';
import { User } from 'features/users/types';
import { call, select } from 'redux-saga/effects';
import {
  selectBreakoutRoomsStatus,
  selectIsBreakoutRoom,
} from 'features/breakout-rooms/breakoutRoomsSlice';
import { selectRoomId } from 'features/room/roomSlice';
import { bulkComputeEncryptionKeysSaga } from 'features/e2ee/sagas/bulkComputeEncryptionKeysSaga';
import { AbstractE2eeUser } from 'features/e2ee/types';

export function* computeBreakoutUserKeysSaga(normalizedRooms: NormalizedBreakoutRoomsConfig) {
  const breakoutRoomsStatus: BreakoutRoomsStatus = yield select(selectBreakoutRoomsStatus);
  if (breakoutRoomsStatus !== 'started') {
    return;
  }

  const {
    unassignedUsers,
    normalizedState: { roomByUser, users },
  } = normalizedRooms;

  const createAbstractE2eeUser = (user: User, roomId: string): AbstractE2eeUser => ({
    id: user.id,
    roomId,
    e2eePublicKey: user.e2eePublicKey!,
  });

  const filteredBreakoutUsers: AbstractE2eeUser[] = [];

  const isBreakoutRoom: boolean = yield select(selectIsBreakoutRoom);
  if (isBreakoutRoom) {
    const roomId: string = yield select(selectRoomId);

    for (const user of Object.values(users)) {
      // exclude users from the current breakout room
      if (roomByUser[user.id] !== roomId) {
        filteredBreakoutUsers.push(createAbstractE2eeUser(user, roomByUser[user.id]));
      }
    }

    for (const user of Object.values(unassignedUsers)) {
      filteredBreakoutUsers.push(createAbstractE2eeUser(user, roomId));
    }
  } else {
    // don't collect unassigned users since we are in the main room and know them
    for (const user of Object.values(users)) {
      filteredBreakoutUsers.push(createAbstractE2eeUser(user, roomByUser[user.id]));
    }
  }

  yield call(bulkComputeEncryptionKeysSaga, filteredBreakoutUsers);
}
