import { User } from 'features/users/types';
import { call, put, select } from 'redux-saga/effects';
import { hasPermissionsSaga } from 'features/permissions/sagas/hasPermissionsSaga';
import { PermissionTypes } from 'features/permissions/types';
import i18n from 'i18n';
import { selectActiveUserById } from 'features/users/usersSlice';
import { E2EEManager } from 'features/e2ee/E2EEManager';
import { userJoinNotificationTriggered } from 'features/notifications/actions';
import { selectE2eeEnabled } from 'features/e2ee/e2eeSlice';
import { Action } from '@reduxjs/toolkit';
import { USER_JOIN_NOTIFICATION_ID } from 'features/notifications/constants';
import { displaySimpleNotification } from 'features/notifications/simple-notifications/displaySimpleNotification';
import {
  selectJoinedUsersCount,
  selectSimpleNotificationById,
} from 'features/notifications/notificationsSlice';
import { updateSimpleNotification } from 'features/notifications/simple-notifications/updateSimpleNotification';
import { SimpleNotification } from 'features/notifications/types';
import * as Sentry from '@sentry/react';
import { logger } from 'utils/logger';
import { selectRoomId } from 'features/room/roomSlice';

export function* handleJoinNotificationSaga(
  action: ReturnType<typeof userJoinNotificationTriggered>
) {
  try {
    const user: User = yield select(selectActiveUserById, action.payload.id);
    if (!user) {
      return;
    }

    const canReceiveNotification: boolean = yield call(
      hasPermissionsSaga,
      PermissionTypes.seeParticipantsPanel
    );

    if (!canReceiveNotification) {
      return;
    }

    const e2eeEnabled: boolean = yield select(selectE2eeEnabled);
    if (e2eeEnabled) {
      if (!user.name) {
        const roomId: string = yield select(selectRoomId);
        yield call(
          E2EEManager.enqueuePendingAction,
          userJoinNotificationTriggered({ id: user.id }),
          roomId
        );

        return;
      }
    }

    const activeNotification: SimpleNotification = yield select(
      selectSimpleNotificationById,
      USER_JOIN_NOTIFICATION_ID
    );

    const joinedCount: number = yield select(selectJoinedUsersCount);

    if (!activeNotification) {
      // show the simple notification with the first username once
      const notificationThunk: Action = yield call(
        displaySimpleNotification,
        {
          content: i18n.t('notifications:user_joined.content_one', { username: user.name }),
        },
        {
          name: 'user_joined',
          toastId: USER_JOIN_NOTIFICATION_ID,
        }
      );
      yield put(notificationThunk);

      return;
    }

    // group incoming notifications
    const notificationThunk: Action = yield call(
      updateSimpleNotification,
      USER_JOIN_NOTIFICATION_ID,
      {
        content: i18n.t('notifications:user_joined.content', {
          count: joinedCount,
        }),
      }
    );
    yield put(notificationThunk);
  } catch (error) {
    Sentry.captureException(error);
    logger.remote({ tier: 1 }).error('Cannot handle user join notification', error);
  }
}
