import { call, fork, select } from 'redux-saga/effects';
import { selectE2eeEnabled } from 'features/e2ee/e2eeSlice';
import { selectIsRecorderSession } from 'features/recorder/recorderSlice';
import { exchangeEncryptionKeysSaga } from 'features/e2ee/sagas/exchangeEncryptionKeysSaga';
import { ratchetEncryptionKeys } from 'features/e2ee/sagas/ratchetEncryptionKeys';
import { generateRoomE2eeCode } from 'features/e2ee/sagas/generateRoomE2eeCode';
import { signalingUserJoined } from 'features/users/actions';
import { selectRoomId } from 'features/room/roomSlice';

export function* onUserJoinedSaga(action: ReturnType<typeof signalingUserJoined>) {
  const e2eeEnabled: boolean = yield select(selectE2eeEnabled);
  if (!e2eeEnabled) {
    return;
  }

  const user = action.payload;
  const roomId: string = yield select(selectRoomId);

  const isRecorderSession: boolean = yield select(selectIsRecorderSession);
  if (isRecorderSession) {
    yield call(exchangeEncryptionKeysSaga, {
      id: user.id,
      roomId,
      e2eePublicKey: user.e2eePublicKey!,
    });

    return;
  }

  yield fork(ratchetEncryptionKeys, {
    id: user.id,
    roomId,
    e2eePublicKey: user.e2eePublicKey!,
  });
  yield fork(generateRoomE2eeCode);
}
