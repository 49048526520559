import { call, select } from 'redux-saga/effects';
import { SFrameManager } from 'features/e2ee/SFrameManager';
import { User } from 'features/users/types';
import { selectUserById } from 'features/users/usersSlice';
import { E2EEManager } from 'features/e2ee/E2EEManager';
import { RTCClient } from 'utils/webrtc';
import { refineSframeStatus } from 'features/e2ee/sagas/refineSframeStatus';
import { E2eeReceiver } from 'features/e2ee/E2eeReceiver';
import { remoteStreamSecretDecrypted } from 'features/e2ee/actions';

export function* onRemoteStreamSecretDecryptedSaga(
  action: ReturnType<typeof remoteStreamSecretDecrypted>
) {
  const user: User = yield select(selectUserById, action.payload.userId);

  if (user) {
    yield call(refineSframeStatus);

    const receiver: E2eeReceiver | undefined = yield call(
      E2EEManager.getReceiver,
      user.id,
      action.payload.roomId
    );
    if (receiver?.streamKey) {
      try {
        yield call(SFrameManager.addReceiver, user.e2eeId!, receiver.streamKey);
        yield call(RTCClient.receivingFeed.subscribeToE2eeUser, user.id);
      } catch {
        // no need to move on
      }
    }
  }
}
