import { fork } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';
import { logger } from 'utils/logger';
import { E2EEManager } from 'features/e2ee/E2EEManager';
import { AbstractE2eeUser } from 'features/e2ee/types';

export function* bulkComputeEncryptionKeysSaga(users: AbstractE2eeUser[]) {
  for (const user of users) {
    try {
      yield fork(E2EEManager.exchangeEncryptionKey, user.id, user.roomId, user.e2eePublicKey);
    } catch (error) {
      Sentry.captureException(error);
      logger
        .remote({ system: true, capture: 'e2ee' })
        .error(`Encryption key computation failed for the userId=${user.id}`, error);
    }
  }
}
