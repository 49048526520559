import { signalingPublicKeyShared } from 'features/e2ee/actions';
import { exchangeEncryptedNameSaga } from 'features/e2ee/sagas/exchangeEncryptedNameSaga';
import { call, select } from 'redux-saga/effects';
import { selectRoomId } from 'features/room/roomSlice';

export function* onPublicKeySharedSaga(action: ReturnType<typeof signalingPublicKeyShared>) {
  const { key, initiatorRoomId, initiator } = action.payload;
  const mainRoomId: string = yield select(selectRoomId);

  yield call(exchangeEncryptedNameSaga, {
    e2eePublicKey: key,
    id: initiator.id,
    roomId: initiatorRoomId || mainRoomId,
  });
}
