import { Button, ButtonProps, IconButton } from '@mui/material';
import { alpha, getContrastRatio, styled } from '@mui/material/styles';
import { useBreakpoints } from 'hooks/useBreakpoints';
import Icon from 'components/Icon';
import Tooltip from 'components/Tooltip';

export type TopbarControlProps = ButtonProps<
  'button',
  {
    tooltipText: string;
    onToggle: () => void;
    buttonText: string | JSX.Element;
    iconName: string;
    // isActive: boolean;
  }
>;

export const Root = styled(Button)(({ theme }) => {
  const bgColor =
    getContrastRatio(theme.palette.common.white, theme.room.background) >
    theme.palette.contrastThreshold
      ? alpha(theme.palette.common.white, 0.2)
      : alpha(theme.palette.action.active, theme.palette.action.hoverOpacity);

  return {
    color: theme.palette.getContrastText(theme.room.background),
    whiteSpace: 'nowrap',
    marginLeft: 'auto',
    marginRight: theme.spacing(2),
    fontSize: 24,
    borderRadius: '20px',
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      fontSize: 14,
      padding: theme.spacing(2, 4),
    },
    '&:hover': {
      backgroundColor: bgColor,
    },
    '&:focus': {
      backgroundColor: bgColor,
    },
    '&.Mui-focusVisible': {
      boxShadow: 'none',
    },
    '&.Mui-disabled': {
      color: theme.palette.grey['600'],
    },
  };
});

const TopbarControl = ({
  tooltipText,
  onToggle,
  buttonText,
  iconName,
  ...props
}: TopbarControlProps) => {
  const { isMobile } = useBreakpoints();

  const renderIcon = () => <Icon name={iconName} />;

  const renderControl = () =>
    isMobile ? (
      <Root onClick={onToggle} size="large" as={IconButton} {...props}>
        {renderIcon()}
      </Root>
    ) : (
      <Root onClick={onToggle} startIcon={renderIcon()} {...props}>
        {buttonText}
      </Root>
    );

  return (
    <Tooltip title={tooltipText} placement="bottom">
      {renderControl()}
    </Tooltip>
  );
};

export default TopbarControl;
