import {
  maximizedContentChanged,
  selectMaximizedContent,
} from 'features/layout/features/content/contentSlice';
import { useRecorder } from 'features/recorder/useRecorder';
import { GridPanelCloseControl } from 'features/layout/GridPanel/GridPanelCloseControl';
import { useBreakpoints } from 'hooks/useBreakpoints';
import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const WhiteboardExpandControl = () => {
  const maximizedContent = useAppSelector(selectMaximizedContent);
  const isRecorder = useRecorder();

  const { isMobile } = useBreakpoints();

  const dispatch = useAppDispatch();

  if (isRecorder) {
    return null;
  }

  const toggleWBExpand = () => {
    if (maximizedContent === 'whiteboard') {
      dispatch(maximizedContentChanged(null));
    } else {
      dispatch(maximizedContentChanged('whiteboard'));
    }
  };

  return isMobile ? null : (
    <GridPanelCloseControl
      icon={maximizedContent === 'whiteboard' ? 'exit-fullscreen' : 'fullscreen'}
      onClick={toggleWBExpand}
    />
  );
};
