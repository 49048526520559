import { decode } from 'features/e2ee/utils/decode';

// Decrypt a file that contains IV (12 bytes) + MIME type + encrypted data.
export const decryptFile = async (encryptedBuffer: ArrayBuffer, encryptionKey: CryptoKey) => {
  // Extract the first 12 bytes as the IV
  const iv = encryptedBuffer.slice(0, 12);

  // Extract the remaining bytes as the ciphertext
  const encryptedData = encryptedBuffer.slice(12);

  const decryptedData = await crypto.subtle.decrypt(
    {
      name: 'AES-GCM',
      iv: new Uint8Array(iv),
    },
    encryptionKey,
    encryptedData
  );

  const decryptedDataView = new Uint8Array(decryptedData);

  // Extract the MIME length (2 bytes)
  const mimeLengthBuffer = decryptedDataView.slice(0, 2);
  // eslint-disable-next-line no-bitwise
  const mimeLength = (mimeLengthBuffer[0] << 8) + mimeLengthBuffer[1];

  // Extract the MIME type
  const mimeTypeBuffer = decryptedDataView.slice(2, 2 + mimeLength);
  const mimeType = decode(mimeTypeBuffer);

  // Extract the file's binary content
  const fileData = decryptedDataView.slice(2 + mimeLength);

  return new Blob([fileData], { type: mimeType });
};
