import { E2EEManager } from 'features/e2ee/E2EEManager';
import { logger } from 'utils/logger';
import * as Sentry from '@sentry/react';

export const getEncryptionKey = (
  whiteboardId: string | null,
  roomId: string
): CryptoKey | undefined => {
  if (!whiteboardId || !roomId) {
    return;
  }

  const whiteboardKey = E2EEManager.whiteboard.getKey(whiteboardId, roomId);

  if (!whiteboardKey?.encryptionKey) {
    const errorMessage = `Encryption key is undefined for whiteboard id=${whiteboardId}.`;
    Sentry.captureException(errorMessage);
    logger.remote({ system: true, capture: 'e2ee' }).warn(errorMessage);

    return;
  }

  return whiteboardKey.encryptionKey;
};
