import { createSelector } from '@reduxjs/toolkit';
import {
  selectBroadcasterTileVisibility,
  selectCurrentLayoutMode,
  selectHideLayoutControls,
  selectLocalStreamMinimized,
} from 'features/layout/features/config/configSlice';
import { selectMaximizedStream } from 'features/layout/features/content/contentSlice';
import { selectExposedMode } from 'features/layout/features/contentDimensions/contentDimensionsSlice';
import { selectOrderedStreams } from 'features/layout/features/order/orderSlice';

import { LayoutMode } from 'features/layout/types';

import { selectLocalBroadcastPermissions } from 'features/permissions/permissionsSlice';
import { selectIsRecorderSession } from 'features/recorder/recorderSlice';
import { BroadcasterTileBehaviour } from 'features/room/types';

import { selectAllBroadcasters } from 'features/streaming/streamingSlice';
import { UsersBroadcastingState } from 'features/streaming/types';
import { UserId } from 'features/users/types';
import { selectLocalUserId } from 'features/users/usersSlice';

export const shouldTileBeVisible = (
  userId: UserId,
  tileVisibility: BroadcasterTileBehaviour,
  broadcasterDevices: UsersBroadcastingState
) => {
  switch (tileVisibility) {
    case 'all':
      return true;
    case 'cam_mic':
      return !!(broadcasterDevices[userId]?.audio || broadcasterDevices[userId]?.video);
    case 'cam':
      return !!broadcasterDevices[userId]?.video;
    default:
      return false;
  }
};

export const selectHideLocalStream = createSelector(
  [
    selectLocalBroadcastPermissions,
    selectIsRecorderSession,
    selectMaximizedStream,
    selectLocalStreamMinimized,
    selectExposedMode,
    selectBroadcasterTileVisibility,
    selectAllBroadcasters,
    selectLocalUserId,
  ],
  (
    allowBroadcast,
    isRecorder,
    maximizedStream,
    minimizeLocal,
    exposedMode,
    tileVisibility,
    broadcasterDevices,
    userId
  ) => {
    if (!allowBroadcast || isRecorder) {
      return true;
    }

    const tileShouldBeVisible = shouldTileBeVisible(userId, tileVisibility, broadcasterDevices);

    if (!tileShouldBeVisible) {
      return true;
    }

    const removeFromGrid = maximizedStream?.kind === 'local' && exposedMode === 'pin';

    return removeFromGrid || minimizeLocal;
  }
);

const prepareOrderedStreams = createSelector(
  [selectCurrentLayoutMode, selectLocalStreamMinimized, selectExposedMode, selectOrderedStreams],
  (layoutMode, localStreamMinimized, exposedMode, orderedStreams) => {
    if (layoutMode === LayoutMode.tiled && exposedMode === 'maximize' && !localStreamMinimized) {
      return [];
    }

    return orderedStreams;
  }
);

// this selector assumes there are no tiles when you're in the expanded view;
export const selectFilteredStreams = createSelector(
  [
    prepareOrderedStreams,
    selectMaximizedStream,
    selectBroadcasterTileVisibility,
    selectAllBroadcasters,
  ],
  (orderedStreams, maximizedStream, tileVisibility, broadcasterDevices) =>
    orderedStreams.filter((stream) => {
      const streamNotMaximized = !(
        stream.userId === maximizedStream?.userId && stream.feedId === maximizedStream?.feedId
      );

      const tileShouldBeVisible =
        stream.kind === 'screenshare-local' || stream.kind === 'screenshare-remote'
          ? true
          : shouldTileBeVisible(stream.userId, tileVisibility, broadcasterDevices);

      return streamNotMaximized && tileShouldBeVisible;
    })
);

// this one still gives you number of tiles below the fold.
const selectVisibleStreams = createSelector(
  [
    selectOrderedStreams,
    selectMaximizedStream,
    selectBroadcasterTileVisibility,
    selectAllBroadcasters,
  ],
  (orderedStreams, maximizedStream, tileVisibility, broadcasterDevices) =>
    orderedStreams.filter((stream) => {
      const streamNotMaximized = !(
        stream.userId === maximizedStream?.userId && stream.feedId === maximizedStream?.feedId
      );

      const tileShouldBeVisible =
        stream.kind === 'screenshare-local' || stream.kind === 'screenshare-remote'
          ? true
          : shouldTileBeVisible(stream.userId, tileVisibility, broadcasterDevices);

      return streamNotMaximized && tileShouldBeVisible;
    })
);

export const selectAdditionalControlsDisabled = createSelector(
  [selectHideLayoutControls, selectVisibleStreams],
  (hideLayoutControls, filteredStreams) =>
    hideLayoutControls === undefined ? !filteredStreams.length : hideLayoutControls
);
