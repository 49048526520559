import Tooltip from 'components/Tooltip';
import { Box } from '@mui/material';
import { PiPControl } from 'features/room/components/topbar/controls/PiPControl';
import LayoutModeControl from 'features/room/components/topbar/controls/LayoutModeControl';
import FullscreenModeControl from 'features/room/components/topbar/controls/FullscreenModeControl';
import React from 'react';
import { usePiPWindow } from 'components/PiP/PiPProvider';
import { openModal } from 'features/modal/modalSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectLayoutModeControlEnabled } from 'features/layout/features/config/configSlice';
import { selectE2eeEnabled } from 'features/e2ee/e2eeSlice';
import { useTranslation } from 'react-i18next';
import { lighten, styled } from '@mui/material/styles';
import E2eeBadge from 'features/e2ee/E2eeBadge';
import { selectRecorderStatus } from 'features/recorder/recorderSlice';

const StyledE2eeBadge = styled(E2eeBadge, {
  shouldForwardProp: (prop) => prop !== 'recording',
})<{ recording?: boolean }>(({ theme, recording }) => {
  let bgColor = '#25D366';

  if (recording) {
    bgColor = theme.palette.grayscale.main;
  }

  return {
    backgroundColor: bgColor,
    '&:hover': {
      backgroundColor: lighten(bgColor, theme.palette.action.focusOpacity),
    },
    '&:focus-visible': {
      backgroundColor: lighten(bgColor, theme.palette.action.focusOpacity),
    },
  };
});

const TopBarControls = React.memo(() => {
  const { t } = useTranslation('room');

  const dispatch = useAppDispatch();

  const recorderStatus = useAppSelector(selectRecorderStatus);
  const recorderIsActive = recorderStatus !== 'stopped';

  const renderE2eeBadgeTooltip = () => {
    if (recorderIsActive) {
      return t('topbar.e2ee_button_tooltip_recording');
    }

    return t('topbar.e2ee_button_tooltip');
  };

  const { pipWindowSupported } = usePiPWindow();

  const openRoomSecurityCode = () => {
    dispatch(openModal('roomE2eeCode'));
  };

  const modeControlEnabled = useAppSelector(selectLayoutModeControlEnabled);

  const e2eeEnabled = useAppSelector(selectE2eeEnabled);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {e2eeEnabled && (
        <Tooltip title={renderE2eeBadgeTooltip()} placement="bottom">
          <StyledE2eeBadge recording={recorderIsActive} onClick={openRoomSecurityCode} />
        </Tooltip>
      )}
      {pipWindowSupported && (
        <Box
          sx={{
            ml: { xs: 2, sm: 5 },
            textAlign: 'right',
          }}
        >
          <PiPControl />
        </Box>
      )}
      {modeControlEnabled && (
        <Box
          sx={{
            ml: { xs: 2, sm: 5 },
            textAlign: 'right',
          }}
        >
          <LayoutModeControl />
        </Box>
      )}
      <FullscreenModeControl />
    </Box>
  );
});

export default TopBarControls;
