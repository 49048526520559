import {
  AssetRecordType,
  createTLStore,
  defaultShapeUtils,
  DocumentRecordType,
  Editor,
  getHashForString,
  InstancePresenceRecordType,
  isGifAnimated,
  MediaHelpers,
  PageRecordType,
  TLAsset,
  TLAssetId,
  TLDocument,
  TLInstancePresence,
  TLPageId,
  TLRecord,
  TLShapeId,
  TLStoreWithStatus,
} from '@digitalsamba/tldraw';
import Uppy, { Meta } from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';
import { notification } from 'features/notifications/toast/notification';
import { UserId } from 'features/users/types';
import { selectUserById } from 'features/users/usersSlice';
import { loaderDisplayChanged, uploadProgressChanged } from 'features/whiteboard/whiteboardSlice';
import i18n from 'i18n';
import { SignalingSocket } from 'services/signaling';
import { store as reduxStore } from 'store/store';
import { singleton } from 'utils/singleton';
import { RTCClient } from 'utils/webrtc';
import { E2EEManager } from 'features/e2ee/E2EEManager';
import { selectRoomId } from 'features/room/roomSlice';
import { e2eeEncrypt } from 'features/e2ee/utils/e2eeEncrypt';
import { getEncryptionKey } from 'utils/whiteboard/getEncryptionKey';
import { encryptFile } from 'features/e2ee/utils/encryptFile';
import { convertToFileWithExtension, fileToBase64, hasValidImageExtension } from 'utils/file';

export type RecordId = TLRecord['id'];

export type RecordList = Record<RecordId, TLRecord>;

export interface WBRemoteUpdateData {
  changed: TLRecord[];
  removed: TLShapeId[];
}

export interface CursorUpdatePayload {
  id: string;
  userId: UserId;
  data: string;
}

type UppyBody = {
  link: string;
};

type HandleImageUploadOptions = {
  file: File;
  fromSdk?: boolean;
  position?: {
    x: number;
    y: number;
  };
};

const CHANGE_INTERVAL = 100;
const POINTER_CHANGE_INTERVAL = 100;

export interface WBCursorCoords {
  x: number;
  y: number;
}

class BoardStateManager {
  whiteboardId: string | null = null;

  intervalId: number = 0;

  pointerChangeIntervalId: number = 0;

  store: TLStoreWithStatus = {
    status: 'loading',
  };

  buffer: RecordList = {};

  removedBuffer: Record<string, boolean> = {};

  initialBuffer: Record<string, boolean> = {};

  localChangesPending = false;

  pointerCoords: {
    x: number;
    y: number;
  } = { x: 0, y: 0 };

  presenceIdByUserId: Record<UserId, TLInstancePresence['id']> = {};

  storedCoordsByUserId: Record<UserId, WBCursorCoords> = {};

  showPointers: boolean = true;

  editor: Editor | null = null;

  uploader: Uppy<Meta, UppyBody> | null = null;

  uploadTokens: Record<string, string> = {};

  shouldInitStore = false;

  private previousPointerCoords: {
    x: number;
    y: number;
  } = { x: 0, y: 0 };

  createStore = () => {
    if (this.store.status !== 'loading') {
      if (this.shouldInitStore) {
        this.store = {
          status: 'loading',
        };

        this.buffer = {};
        this.removedBuffer = {};
        this.initialBuffer = {};
        this.localChangesPending = false;
        this.stop();
      } else {
        return;
      }
    }

    this.shouldInitStore = false;

    const store = createTLStore({ shapeUtils: defaultShapeUtils });

    store.put([
      DocumentRecordType.create({
        id: 'document:document' as TLDocument['id'],
      }),

      PageRecordType.create({
        id: 'page:page' as TLPageId,
        name: 'Page 1',
        index: 'a1',
      }),
    ]);

    store.listen(
      ({ changes }) => {
        Object.values(changes.added).forEach((record) => {
          this.insertShape(record.id, record);
        });

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        Object.values(changes.updated).forEach(([_, record]) => {
          this.insertShape(record.id, record);
        });

        Object.values(changes.removed).forEach((record) => {
          this.removeShape(record.id);
        });
      },
      { source: 'user', scope: 'document' }
    );

    // old store will be gargbage collected, right? =);
    this.store = {
      store,
      status: 'synced-remote',
      connectionStatus: 'online',
    };

    reduxStore.dispatch(loaderDisplayChanged(false));
  };

  insertShape = (id: RecordId, shape: any) => {
    if (!this.initialBuffer[id]) {
      this.buffer[id] = shape;

      delete this.removedBuffer[id];

      this.localChangesPending = true;
    }

    delete this.initialBuffer[id];
  };

  removeShape = (id: RecordId) => {
    delete this.buffer[id];
    delete this.initialBuffer[id];

    this.removedBuffer[id] = true;

    this.localChangesPending = true;
  };

  watch = () => {
    this.intervalId = window.setInterval(this.checkForChanges, CHANGE_INTERVAL);
    this.pointerChangeIntervalId = window.setInterval(
      this.updatePointerCoords,
      POINTER_CHANGE_INTERVAL
    );
  };

  stop = () => {
    clearInterval(this.intervalId);
    clearInterval(this.pointerChangeIntervalId);
  };

  setState = (records: TLRecord[]) => {
    this.initialBuffer = records.reduce<Record<string, boolean>>((buffer, record) => {
      buffer[record.id] = true;
      return buffer;
    }, {});

    this.store.store?.put(records);
  };

  acceptRemoteChanges = (data: WBRemoteUpdateData) => {
    this.store.store?.mergeRemoteChanges(() => {
      const toPut = data.changed;
      const toRemove = data.removed;

      if (toPut.length) {
        this.store.store?.put(toPut);
      }
      if (toRemove.length) {
        this.store.store?.remove(toRemove);
      }
    });
  };

  updateRemotePointer = (data: { id: string; userId: UserId; coords: WBCursorCoords }) => {
    if (!this.store.store) {
      return;
    }

    this.storedCoordsByUserId[data.userId] = data.coords;

    if (!this.showPointers) {
      return;
    }

    const user = selectUserById(reduxStore.getState(), data.userId);

    if (!(user?.name && user?.avatarColor)) {
      return;
    }

    const presence = InstancePresenceRecordType.create({
      id: InstancePresenceRecordType.createId(data.userId),
      currentPageId: 'page:page' as TLPageId,
      userId: data.userId,
      userName: user.name,
      color: user.avatarColor,
      cursor: { x: data.coords.x, y: data.coords.y, type: 'default', rotation: 0 },
      lastActivityTimestamp: Date.now(),
    });

    this.presenceIdByUserId[data.userId] = presence.id;

    this.store.store.mergeRemoteChanges(() => {
      this.store.store?.put([presence]);
    });
  };

  removeRemotePointer = (id: UserId) => {
    if (this.store.store && this.presenceIdByUserId[id]) {
      this.store.store.mergeRemoteChanges(() => {
        this.store.store?.remove([this.presenceIdByUserId[id]]);
      });
    }
  };

  togglePointers = () => {
    if (this.showPointers) {
      Object.keys(this.presenceIdByUserId).forEach((key) => {
        this.removeRemotePointer(key);
      });

      this.showPointers = false;
    } else {
      this.showPointers = true;

      Object.entries(this.storedCoordsByUserId).forEach(([userId, coords]) => {
        this.updateRemotePointer({ id: this.whiteboardId!, userId, coords });
      });
    }

    return this.showPointers;
  };

  setReadonly = (state: boolean) => {
    if (this.editor) {
      this.editor.updateInstanceState({ isReadonly: state });
    }
  };

  getUploader = () => {
    if (!this.uploader) {
      this.uploader = new Uppy({
        restrictions: {
          maxFileSize: 10 * (1024 * 1024),
        },
        autoProceed: true,
        // allow duplicate files (bc i KNOW people will try to)
        onBeforeFileAdded: () => true,
      });

      this.uploader.use(XHRUpload, {
        endpoint: `${process.env.REACT_APP_API_URL}/room-api/${RTCClient.roomId}/files/upload`,
        formData: true,
        fieldName: 'file',
        headers: (file) => {
          const token = this.uploadTokens[file.name!];

          return {
            authorization: `Bearer ${token}`,
          };
        },
      });

      this.uploader.on('progress', (progress) => {
        reduxStore.dispatch(uploadProgressChanged(progress));
      });
    }

    return this.uploader;
  };

  handleImageUpload = async ({ file, fromSdk, position }: HandleImageUploadOptions) => {
    const tokenResponse = (await SignalingSocket.sendAsync({ event: 'requestFileUpload' })) as {
      token: string;
    };

    if (!hasValidImageExtension(file)) {
      file = convertToFileWithExtension(file);
    }

    if (tokenResponse?.token) {
      this.uploadTokens[file.name] = tokenResponse.token;
    }

    const uploader = this.getUploader();
    let fileId: string;
    const mimeType = file.type;

    try {
      if (E2EEManager.e2eeEnabled) {
        const roomId = selectRoomId(reduxStore.getState());

        const encryptionKey = getEncryptionKey(this.whiteboardId, roomId);
        if (!encryptionKey) {
          return;
        }

        const fileBuffer = await file.arrayBuffer();
        const encryptedBlob = await encryptFile(fileBuffer, encryptionKey, mimeType);

        fileId = uploader.addFile({
          name: file.name,
          type: 'wb-image',
          data: encryptedBlob,
        });
      } else {
        fileId = uploader.addFile({
          name: file.name,
          type: 'wb-image',
          data: file,
        });
      }
    } catch (e) {
      notification(i18n.t('notifications:wb_upload_file_invalid'));
      return undefined;
    }

    const result = await uploader.upload();
    if (!result) {
      return;
    }

    if (result.successful?.[0]) {
      const res = result.successful[0];
      const url = res.response?.body!.link as string;

      const dataUrl = await fileToBase64(file);

      const size = await MediaHelpers.getImageSizeFromSrc(dataUrl);
      const assetId: TLAssetId = AssetRecordType.createId(getHashForString(url));
      const isAnimated = mimeType === 'image/gif' && (await isGifAnimated(file));

      // use base64 file in case of E2EE
      const src = E2EEManager.e2eeEnabled ? dataUrl : url;

      const asset: TLAsset = AssetRecordType.create({
        id: assetId,
        type: 'image',
        typeName: 'asset',
        props: {
          name: file.name,
          src,
          w: size.w,
          h: size.h,
          mimeType,
          isAnimated,
        },
        meta: E2EEManager.e2eeEnabled
          ? {
              // preserve the original url with the encrypted file
              src: url,
            }
          : undefined,
      });

      uploader.removeFile(fileId);

      if (fromSdk && this.editor) {
        this.editor.createAssets([asset]);

        this.editor.createShape({
          type: 'image',
          x: position?.x ?? (window.innerWidth - size.w) / 2,
          y: position?.y ?? (window.innerHeight - size.h) / 2,
          props: {
            assetId,
            w: size.w,
            h: size.h,
          },
        });
      }

      return asset;
    }

    if (result.failed?.[0]) {
      reduxStore.dispatch(uploadProgressChanged(0));
      uploader.removeFile(fileId);
      notification(i18n.t('notifications:wb_upload_failed'));

      return undefined;
    }

    return undefined;
  };

  private flushBuffer = async () => {
    if (!this.localChangesPending) {
      return;
    }

    const buffer = { ...this.buffer };
    const removedBuffer = { ...this.removedBuffer };

    this.buffer = {};
    this.removedBuffer = {};
    this.localChangesPending = false;

    let encryptionKey: CryptoKey | undefined;

    if (E2EEManager.e2eeEnabled) {
      const roomId = selectRoomId(reduxStore.getState());

      encryptionKey = getEncryptionKey(this.whiteboardId, roomId);
      if (!encryptionKey) {
        return;
      }
    }

    const promises = Object.entries(buffer).map(async ([recordId, record]) => {
      let data = record;

      // small hack for an E2EE scenario, swap base64 url with the original one before sending
      if (E2EEManager.e2eeEnabled && record.typeName === 'asset' && record.meta.src) {
        const copiedRecord = JSON.parse(JSON.stringify(record));
        copiedRecord.props.src = record.meta.src;
        // @TODO do we need to completely remove meta? so far it's acceptable
        copiedRecord.meta = {};

        data = copiedRecord;
      }

      const serializedData = JSON.stringify(data);

      return {
        shapeId: recordId,
        data: encryptionKey ? await e2eeEncrypt(serializedData, encryptionKey) : serializedData,
      };
    });

    const changed = await Promise.all(promises);
    const removed = Object.keys(removedBuffer);

    SignalingSocket.send({
      event: 'updateWhiteboard',
      data: {
        id: this.whiteboardId,
        changed,
        removed,
      },
    });
  };

  private checkForChanges = () => {
    if (this.localChangesPending) {
      this.flushBuffer();
    }
  };

  private updatePointerCoords = async () => {
    if (
      this.pointerCoords.x !== this.previousPointerCoords.x ||
      this.pointerCoords.y !== this.previousPointerCoords.y
    ) {
      let encryptionKey: CryptoKey | undefined;

      if (E2EEManager.e2eeEnabled) {
        const roomId = selectRoomId(reduxStore.getState());

        encryptionKey = getEncryptionKey(this.whiteboardId, roomId);
        if (!encryptionKey) {
          return;
        }
      }

      const serializedData = JSON.stringify(this.pointerCoords);
      const data = encryptionKey
        ? await e2eeEncrypt(serializedData, encryptionKey)
        : serializedData;

      SignalingSocket.send({
        event: 'updateWhiteboardCursor',
        data: {
          id: this.whiteboardId,
          data,
        },
      });

      this.previousPointerCoords = this.pointerCoords;
    }
  };
}

export const board = singleton<BoardStateManager>(() => new BoardStateManager());
