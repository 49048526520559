import { signalingWaitingUsersUpdated } from 'features/users/actions';
import { call, put, select } from 'redux-saga/effects';
import { bulkComputeEncryptionKeysSaga } from 'features/e2ee/sagas/bulkComputeEncryptionKeysSaga';
import { selectE2eeEnabled } from 'features/e2ee/e2eeSlice';
import { waitingEntryNotificationUpdateRequested } from 'features/notifications/actions';
import { transformToAbstractE2ee } from 'features/e2ee/utils/transformUsersToAbstractE2ee';
import { selectRoomId } from 'features/room/roomSlice';
import { AbstractE2eeUser } from 'features/e2ee/types';

export function* onWaitingUsersUpdatedSaga(
  action: ReturnType<typeof signalingWaitingUsersUpdated>
) {
  const e2eeEnabled: boolean = yield select(selectE2eeEnabled);
  if (e2eeEnabled) {
    const roomId: string = yield select(selectRoomId);
    const users: AbstractE2eeUser[] = yield call(
      transformToAbstractE2ee,
      action.payload,
      () => roomId
    );
    yield call(bulkComputeEncryptionKeysSaga, users);
  }

  yield put(waitingEntryNotificationUpdateRequested());
}
