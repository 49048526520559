import { TLRecord } from '@digitalsamba/tldraw';

export const parseBoardState = (records: string[]) => {
  const result: TLRecord[] = [];
  for (const record of records) {
    const parsedRecord: TLRecord = JSON.parse(record);
    result.push(parsedRecord);
  }

  return result;
};
