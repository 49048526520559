import { createAction } from '@reduxjs/toolkit';
import {
  EncryptedNameSharedPayload,
  EncryptionKeySharedPayload,
  PublicKeySharedPayload,
  UserNameDecrypted,
  WhiteboardKeyRequestedPayload,
  WhiteboardKeySharedPayload,
} from 'features/e2ee/types';
import { UserId } from 'features/users/types';

export const signalingEncryptionKeyShared = createAction<EncryptionKeySharedPayload>(
  'signaling/encryptionKeyShared'
);

export const signalingPublicKeyShared = createAction<PublicKeySharedPayload>(
  'signaling/publicKeyShared'
);

export const signalingEncryptedNameShared = createAction<EncryptedNameSharedPayload>(
  'signaling/encryptedNameShared'
);

export const sframeInitialized = createAction('e2ee/sframeInitialized');

export const applicationKeyGenerated = createAction('e2ee/applicationKeyGenerated');

export const remoteStreamSecretDecrypted = createAction<{ userId: UserId; roomId: string }>(
  'e2ee/remoteStreamSecretDecrypted'
);
export const userNameDecrypted = createAction<UserNameDecrypted>('e2ee/userNameDecrypted');

export const signalingWhiteboardKeyShared = createAction<WhiteboardKeySharedPayload>(
  'signaling/whiteboardKeyShared'
);

export const signalingWhiteboardKeyRequested = createAction<WhiteboardKeyRequestedPayload>(
  'signaling/whiteboardKeyRequested'
);

export const whiteboardKeyDecrypted = createAction<{ id: string; roomId: string }>(
  'e2ee/whiteboardKeyDecrypted'
);
