import { call, select } from 'redux-saga/effects';
import { E2EEManager } from 'features/e2ee/E2EEManager';
import { selectE2eeEnabled } from 'features/e2ee/e2eeSlice';
import { signalingWhiteboardKeyShared } from 'features/e2ee/actions';

export function* onWhiteboardKeySharedSaga(
  action: ReturnType<typeof signalingWhiteboardKeyShared>
) {
  const e2eeEnabled: boolean = yield select(selectE2eeEnabled);
  if (e2eeEnabled) {
    const { initiator, key, initiatorRoomId, id } = action.payload;

    yield call(E2EEManager.decryptRemoteData, initiator.id, initiatorRoomId, {
      whiteboard: {
        id,
        key,
      },
    });
  }
}
