import { fork, select } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';
import { logger } from 'utils/logger';
import { User } from 'features/users/types';
import { selectRoomId } from 'features/room/roomSlice';
import { exchangeWhiteboardKeySaga } from 'features/e2ee/sagas/exchangeWhiteboardKeySaga';
import { selectUsersWithoutLocal } from 'features/users/usersSlice';

export function* exchangeCreatedWhiteboardKeySaga(whiteboardId: string) {
  const roomId: string = yield select(selectRoomId);
  const users: User[] = yield select(selectUsersWithoutLocal);

  for (const user of users) {
    try {
      yield fork(exchangeWhiteboardKeySaga, whiteboardId, {
        id: user.id,
        roomId,
        e2eePublicKey: user.e2eePublicKey!,
      });
    } catch (error) {
      Sentry.captureException(error);
      logger
        .remote({ system: true, capture: 'e2ee' })
        .error(`Whiteboard key exchange failed with the userId=${user.id}`, error);
    }
  }
}
