import { useFeatureFlag } from 'features/feature-set/useFeatureFlag';
import { streamMaximized, streamMinimized } from 'features/layout/actions';
import { selectCurrentLayoutMode } from 'features/layout/features/config/configSlice';
import { selectMaximizedStream } from 'features/layout/features/content/contentSlice';
import {
  contentWidthChanged,
  selectExposedMode,
} from 'features/layout/features/contentDimensions/contentDimensionsSlice';
import { LayoutMode, SourceDetails } from 'features/layout/types';
import { sourceMatches } from 'features/streaming/utils';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const useExpandControl = (
  source: SourceDetails,
  clickCallback: (e: React.MouseEvent<HTMLLIElement | HTMLButtonElement, MouseEvent>) => void
) => {
  const { t } = useTranslation('room');
  const dispatch = useAppDispatch();

  const maximizedFeed = useAppSelector(selectMaximizedStream);
  const exposedMode = useAppSelector(selectExposedMode);

  const controlActive = sourceMatches(maximizedFeed, source) && exposedMode === 'maximize';

  const onClick = (e: React.MouseEvent<HTMLLIElement | HTMLButtonElement, MouseEvent>) => {
    clickCallback?.(e);
    if (controlActive) {
      dispatch(streamMinimized());
    } else {
      dispatch(contentWidthChanged(1));
      dispatch(streamMaximized({ source }));
    }
  };

  const enabled = useFeatureFlag('fullScreen');
  const layoutMode = useAppSelector(selectCurrentLayoutMode);

  const showControl = () => {
    if (!enabled) {
      return false;
    }

    return layoutMode !== LayoutMode.auto;
  };

  return {
    show: showControl(),
    text: controlActive ? t('tooltips.minimize') : t('tooltips.maximize'),
    icon: controlActive ? 'exit-fullscreen' : 'fullscreen',
    onClick,
  };
};
