import { signalingWhiteboardCursorUpdated } from 'features/layout/features/content/actions';
import { call, select } from 'redux-saga/effects';
import { board, WBCursorCoords } from 'utils/whiteboard/BoardStateManager';
import { decryptWhiteboardCursorPositionChangeSaga } from 'features/layout/sagas/decryptWhiteboardCursorPositionChangeSaga';
import { selectE2eeEnabled } from 'features/e2ee/e2eeSlice';
import * as Sentry from '@sentry/react';

export function* onWhiteboardCursorPositionChangedSaga(
  action: ReturnType<typeof signalingWhiteboardCursorUpdated>
) {
  const { id, userId, data: stringifiedCoords } = action.payload;

  let coords: WBCursorCoords;

  const e2eeEnabled: boolean = yield select(selectE2eeEnabled);

  try {
    if (e2eeEnabled) {
      coords = yield call(decryptWhiteboardCursorPositionChangeSaga, id, stringifiedCoords);
    } else {
      coords = JSON.parse(stringifiedCoords);
    }

    yield call(board.updateRemotePointer, { id, userId, coords });
  } catch (error) {
    Sentry.captureException(error);
  }
}
