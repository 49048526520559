import React from 'react';
import { Box, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ToolbarPlacement } from 'features/toolbar/types';
import RecordingIndicator from 'features/toolbar/RecordingIndicator';
import SlidingRecordingIndicator from 'features/room/components/topbar/SlidingRecordingIndicator';
import { styleConstants } from 'theme/styleConstants';
import { useAppSelector } from 'store/hooks';
import { selectRoomLogoEnabled } from 'features/room/roomSlice';
import { selectToolbarPlacement } from 'features/toolbar/toolbarSlice';
import { selectRecorderStatus } from 'features/recorder/recorderSlice';
import Logo from 'features/room/components/topbar/Logo';
import TranscriptionBadge from 'features/transcripts/slices/transcription/TranscriptionBadge';
import { selectTranscriptionStarted } from 'features/transcripts/slices/transcription/transcriptionSlice';
import { selectIsBreakoutRoom } from 'features/breakout-rooms/breakoutRoomsSlice';
import TopBarControls from 'features/room/components/topbar/TopBarControls';

const SlidingWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  transition: theme.transitions.create('transform'),
}));

const Root = styled('div')<{ toolbarPlacement: ToolbarPlacement }>(({
  theme,
  toolbarPlacement,
}) => {
  const paddingLeft =
    toolbarPlacement === 'left'
      ? `calc(${theme.layout.innerPadding} + ${theme.toolbar.width})`
      : theme.layout.innerPadding;

  return {
    color: theme.palette.common.white,
    paddingLeft,
    background: theme.room.background,
    zIndex: 2,
  };
});

const Inner = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  height: theme.topBar.height,
  paddingTop: theme.spacing(2),
}));

const RoomTopBar = () => {
  const logoEnabled = useAppSelector(selectRoomLogoEnabled);

  const toolbarPlacement = useAppSelector(selectToolbarPlacement);

  const recorderStatus = useAppSelector(selectRecorderStatus);

  const transcriptionStarted = useAppSelector(selectTranscriptionStarted);

  const isBreakoutRoom = useAppSelector(selectIsBreakoutRoom);

  const logo = logoEnabled ? <Logo data-testid="topbar-logo" /> : null;

  const recorderIsActive = recorderStatus !== 'stopped';

  const slidingWrapperTranslateWidth =
    styleConstants.featureBadge.width + (transcriptionStarted ? 8 : 16);

  const displayTranscription = !isBreakoutRoom && transcriptionStarted;

  return (
    <Root toolbarPlacement={toolbarPlacement}>
      {toolbarPlacement === 'left' && <SlidingRecordingIndicator />}
      <Inner>
        {toolbarPlacement === 'left' ? (
          <Box
            sx={{
              flex: 1,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {displayTranscription && (
                <Box
                  sx={{
                    pr: 2,
                  }}
                >
                  <TranscriptionBadge />
                </Box>
              )}
              {logo}
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              flex: 1,
            }}
          >
            <SlidingWrapper
              sx={{
                transform: recorderIsActive
                  ? 'translateX(0)'
                  : `translateX(-${slidingWrapperTranslateWidth}px)`,
              }}
              data-testid="topbar-sliding-logo"
            >
              <Stack
                sx={{
                  display: 'flex',
                  pr: 4,
                }}
                spacing={2}
                direction="row"
              >
                <RecordingIndicator
                  recorderStatus={recorderStatus}
                  data-testid="recording-indicator"
                />
                {displayTranscription && <TranscriptionBadge />}
              </Stack>
              {logo}
            </SlidingWrapper>
          </Box>
        )}
        <TopBarControls />
      </Inner>
    </Root>
  );
};

export default RoomTopBar;
