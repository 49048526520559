import { all, call, fork, select } from 'redux-saga/effects';
import { User } from 'features/users/types';
import { refreshStreamKey } from 'features/e2ee/sagas/refreshStreamKey';
import { selectUsersWithoutLocal } from 'features/users/usersSlice';
import { bulkExchangeEncryptionKeysSaga } from 'features/e2ee/sagas/bulkExchangeEncryptionKeysSaga';
import { refreshApplicationKey } from 'features/e2ee/sagas/refreshApplicationKey';
import { selectRecorder } from 'features/recorder/recorderSlice';
import { Recorder } from 'features/recorder/types';
import { exchangeEncryptionKeysSaga } from 'features/e2ee/sagas/exchangeEncryptionKeysSaga';
import { selectRoomId } from 'features/room/roomSlice';

export function* refreshEncryptionKeys() {
  yield all([call(refreshStreamKey), call(refreshApplicationKey)]);

  const roomUsers: User[] = yield select(selectUsersWithoutLocal);

  yield fork(bulkExchangeEncryptionKeysSaga, roomUsers);

  const roomId: string = yield select(selectRoomId);

  // exchange the key with the recorder if any
  const recorder: Recorder = yield select(selectRecorder);
  if (recorder) {
    yield fork(exchangeEncryptionKeysSaga, {
      id: recorder.id,
      roomId,
      e2eePublicKey: recorder.e2eePublicKey,
    });
  }
}
