import { createAction } from '@reduxjs/toolkit';
import {
  SignalingUserLeftPayload,
  User,
  UserHandLoweredPayload,
  UserHandRaisedPayload,
  UserId,
  UserLeftBatchPayload,
} from 'features/users/types';
import { EventInitiator } from 'services/signaling';

export const signalingUserJoined = createAction<User>('signaling/userJoined');
export const signalingUserLeft = createAction<SignalingUserLeftPayload>('signaling/userLeft');
export const signalingUserLeftBatch = createAction<UserLeftBatchPayload>('signaling/userLeftBatch');

export const signalingHandRaised = createAction<UserHandRaisedPayload>('signaling/handRaised');
export const signalingHandLowered = createAction<UserHandLoweredPayload>('signaling/handLowered');

export const kickUser = createAction<UserId>('users/kick');
export const signalingUserKicked = createAction<{
  reason: 'kicked_by_user' | 'session_duplicated';
  initiator: EventInitiator;
}>('signaling/kicked');

// room entry control
export const admitEntry = createAction<UserId>('users/admitEntry');
export const rejectEntry = createAction<UserId>('users/rejectEntry');
export const bulkRejectEntry = createAction('users/bulkRejectEntry');
export const bulkAdmitEntry = createAction('users/bulkAdmitEntry');

export const signalingWaitingUserJoined = createAction<User>('signaling/waitingUserJoined');
export const signalingWaitingUserLeft = createAction<SignalingUserLeftPayload>(
  'signaling/waitingUserLeft'
);
export const signalingWaitingUserLeftBatch = createAction<UserLeftBatchPayload>(
  'signaling/waitingUserLeftBatch'
);
export const signalingWaitingUsersUpdated = createAction<User[]>('signaling/waitingUsersUpdated');
export const clearWaitingUsersList = createAction('users/clearWaitingUsersList');
export const signalingAccessRejected = createAction('signaling/accessRejected');
export const signalingBulkAccessRejected = createAction('signaling/bulkAccessRejected');

export const kickUserRequested = createAction<UserId>('users/kickRequested');
