import React, { useId } from 'react';
import { useAppDispatch } from 'store/hooks';
import { closeModal } from 'features/modal/modalSlice';
import { useTranslation } from 'react-i18next';
import { DialogContent, DialogContentText } from '@mui/material';
import Button from '@mui/material/Button';
import { Dialog, DialogTitle, DialogActions } from 'components/Dialog';

const WhiteboardAlreadyOpenedModal = () => {
  const dispatch = useAppDispatch();

  const prefix = useId();

  const { t } = useTranslation('modals');

  const hideModal = () => dispatch(closeModal('whiteboardAlreadyOpened'));

  return (
    <Dialog
      open
      aria-labelledby={`${prefix}-title`}
      aria-describedby={`${prefix}-description`}
      onClose={hideModal}
      maxWidth="xs"
    >
      <DialogTitle id={`${prefix}-title`} onClose={hideModal}>
        {t('whiteboard_already_opened.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id={`${prefix}-description`}>
          {t('whiteboard_already_opened.description')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={hideModal}>{t('whiteboard_already_opened.btn_ok_label')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default WhiteboardAlreadyOpenedModal;
