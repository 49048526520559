import { encode } from 'features/e2ee/utils/encode';
import { generateIv } from 'features/e2ee/utils/generateIv';

export const encryptString = async (data: string, key: CryptoKey) => {
  const encoded = encode(data);
  const iv = generateIv();
  const cipher = await window.crypto.subtle.encrypt(
    {
      name: 'AES-GCM',
      iv,
    },
    key,
    encoded
  );

  return {
    cipher,
    iv,
  };
};
