import { debugToggled, targetTilesCountSet } from 'features/dev-mode/devModeSlice';
import { store } from 'store/store';
import { MediaStreamStats } from 'utils/webrtc/MediaStreamStats';
import { RTCClient } from 'utils/webrtc';
import { SubscriberMid } from 'features/streaming/types';
import { receivingStatsUpdated } from 'features/system-stats/systemStatsSlice';
import { ReceivingMidStats } from 'features/system-stats/types';

export class DevMode {
  debugEnabled: boolean = false;

  statsTimer?: number;

  constructor() {
    window.addEventListener('keydown', (event) => {
      if (event.altKey && event.shiftKey && event.key.toLowerCase() === 'd') {
        this.toggleDebug();
      }
    });
  }

  private toggleDebug = () => {
    if (this.debugEnabled) {
      this.stopCapturingMediaStats();
    } else {
      this.captureMediaStats();
    }

    this.debugEnabled = !this.debugEnabled;
    store.dispatch(debugToggled());
  };

  private captureMediaStats = () => {
    if (this.statsTimer) {
      return;
    }

    this.statsTimer = window.setInterval(async () => {
      const receivingStats: MediaStreamStats[] = Object.values(
        RTCClient.receivingFeed.streamsStats
      );

      await Promise.all(
        receivingStats.map(async (stream) => {
          await stream.getStats();
        })
      );

      let totalBandwidth = 0;
      const byMid: Record<SubscriberMid, ReceivingMidStats> = {};

      for (const stats of receivingStats) {
        totalBandwidth += stats.bitrate;
        const mid = stats.mid as SubscriberMid;

        byMid[mid] = {
          kind: stats.kind!,
          feedId: stats.feedId!,
          mid,
          bitrate: stats.bitrate,
          fps: stats.fps,
          codec: stats.codec,
          videoWidth: stats.videoWidth,
          videoHeight: stats.videoHeight,
        };
      }

      const normalizedStats = { totalBandwidth, byMid };

      store.dispatch(receivingStatsUpdated(normalizedStats));
    }, 1000);
  };

  private stopCapturingMediaStats = () => {
    if (this.statsTimer) {
      window.clearInterval(this.statsTimer);
      this.statsTimer = undefined;
    }
  };

  setTargetTiles = (count: number) => {
    store.dispatch(targetTilesCountSet(count));
  };
}
