import { useTheme } from '@mui/material/styles';
import { PiPPlaceholder } from 'components/PiP/PiPPlaceholder';
import { usePiPWindow } from 'components/PiP/PiPProvider';
import { selectHideTilesInRecordings } from 'features/layout/features/config/configSlice';
import LayoutRoot from 'features/layout/LayoutRoot/LayoutRoot';
import { Box, GlobalStyles } from '@mui/material';
import { useRecorder } from 'features/recorder/useRecorder';
import ToolbarPanelManager from 'features/room/components/ToolbarPanelManager';
import RoomLayoutWrapper from 'features/room/components/RoomLayoutWrapper';
import { selectToolbarPlacement } from 'features/toolbar/toolbarSlice';
import { useAudio } from 'hooks/useAudio';
import { useThemeMeta } from 'hooks/useThemeMeta';
import React, { useEffect, useRef } from 'react';
import joinSound from 'assets/sounds/join-room.wav';
import RoomModalManager from 'features/room/RoomModalManager';
import { useTranslation } from 'react-i18next';
import { lazyNamespaces } from 'i18n';
import TopBar from 'features/room/components/topbar/TopBar';
import { RecorderGuard } from 'features/recorder/RecorderGuard';
import { useToolbarOrientation } from 'hooks/useToolbarOrientation';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectIsBroadcastSetupActive } from 'features/room/roomSlice';
import BroadcastSetup from 'features/room/broadcast-setup/BroadcastSetup';
import { roomRendered } from 'features/room/actions';
import { FeatureGuard } from 'features/feature-set/FeatureGuard';
import BreakoutsSplashScreenManager from 'features/breakout-rooms/components/splash-screen/BreakoutsSplashScreenManager';
import { ScreenshareRecorderRoot } from 'features/layout/LayoutRoot/ScreenshareRecorderRoot';
import RoomKeyboardShortcuts from 'features/room/components/RoomKeyboardShortcuts';

const bodyStyles = (
  <GlobalStyles
    styles={(theme) => ({
      body: {
        overflow: 'hidden',
        backgroundColor: theme.room.background,
      },
      '.tl-theme__light': {
        '--color-selected': `${theme.palette.primary.main}`,
        '--color-selected-contrast': `${theme.palette.primary.contrastText}`,
      },
    })}
  />
);

const Room = () => {
  const didInitRef = useRef(false);

  const { i18n } = useTranslation();

  const { audio } = useAudio();
  const theme = useTheme();

  const dispatch = useAppDispatch();

  const isBroadcastSetupActive = useAppSelector(selectIsBroadcastSetupActive);

  const isRecorder = useRecorder();

  const hideTilesInRecordings = useAppSelector(selectHideTilesInRecordings);

  const { pipWindow } = usePiPWindow();

  useThemeMeta(theme.room.background);

  useToolbarOrientation();

  useEffect(() => {
    if (!didInitRef.current) {
      didInitRef.current = true;
      dispatch(roomRendered());
    }
  }, [dispatch]);

  useEffect(() => {
    i18n.loadNamespaces(lazyNamespaces);
  }, [i18n]);

  useEffect(() => {
    const playSound = () => {
      if (audio) {
        audio.src = joinSound;
        audio.play().catch(() => {
          // mute possible NotAllowedError on mobile devices
        });
      }
    };

    playSound();
  }, [audio]);

  const toolbarPlacement = useAppSelector(selectToolbarPlacement);

  const renderLayout = () => {
    if (pipWindow) {
      return <PiPPlaceholder />;
    }

    if (!(isRecorder && hideTilesInRecordings)) {
      return <LayoutRoot />;
    }

    return <ScreenshareRecorderRoot />;
  };

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          height: 'var(--app-height)',
          backgroundColor: theme.room.background,
        }}
      >
        {bodyStyles}
        <TopBar />
        <RoomLayoutWrapper key={toolbarPlacement}>{renderLayout()}</RoomLayoutWrapper>
      </Box>
      <RecorderGuard>
        <RoomModalManager />
      </RecorderGuard>
      <ToolbarPanelManager />
      {isBroadcastSetupActive && <BroadcastSetup />}
      <FeatureGuard feature="breakoutRooms">
        <BreakoutsSplashScreenManager />
      </FeatureGuard>
      <RoomKeyboardShortcuts />
    </>
  );
};

export default Room;
