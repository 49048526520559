const mimeToExtension: Record<string, string> = {
  'image/jpeg': 'jpeg',
  'image/jpg': 'jpg',
  'image/png': 'png',
  'image/gif': 'gif',
  'image/webp': 'webp',
  'image/svg+xml': 'svg',
  'image/avif': 'avif',
};

export const fileToBase64 = (file: File | Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.onabort = (error) => reject(error);
  });

export const base64ToFile = (packed: string, fileName: string = 'image.png') => {
  try {
    const byteString = atob(packed.split(',')[1]);

    const byteArray = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      byteArray[i] = byteString.charCodeAt(i);
    }

    const mimeString = packed.split(',')[0].split(':')[1].split(';')[0];
    return new File([byteArray], fileName, { type: mimeString });
  } catch (e) {
    return null;
  }
};

export const urlToFile = async (url: string = '', fileName: string = 'image.png') => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    return new File([blob], fileName, { type: blob.type });
  } catch (e) {
    return null;
  }
};

export const urlToArrayBuffer = async (url: string) => {
  const response = await fetch(url);
  return response.arrayBuffer();
};

export const getFileExtension = (fileName: string) => {
  const match = fileName.match(/\.([a-zA-Z0-9]+)$/);
  return match ? match[1].toLowerCase() : null;
};

export const hasValidImageExtension = (file: File) => {
  if (!file?.name) {
    return false;
  }

  const fileExtension = getFileExtension(file.name);
  if (!fileExtension) {
    return false;
  }

  const expectedExtension = mimeToExtension[file.type];
  return expectedExtension ? expectedExtension === fileExtension : true;
};

export const convertToFileWithExtension = (file: File) => {
  if (!file) {
    throw new Error('No file provided.');
  }

  const correctExtension = mimeToExtension[file.type];
  if (!correctExtension) {
    // Unsupported MIME type, return the file unchanged
    return file;
  }

  const newFileName = `${file.name}.${correctExtension}`;

  return new File([file], newFileName, { type: file.type });
};
