import { useTranslation } from 'react-i18next';
import {
  layoutModeChanged,
  selectCurrentLayoutMode,
} from 'features/layout/features/config/configSlice';
import { LayoutMode } from 'features/layout/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import TopbarControl from 'features/room/components/topbar/TopbarControl';

const LayoutModeControl = () => {
  const dispatch = useAppDispatch();
  const currentMode = useAppSelector(selectCurrentLayoutMode);
  const { t } = useTranslation('room');

  const isAutoMode = currentMode === LayoutMode.auto;

  const handleToggle = () => {
    dispatch(layoutModeChanged(isAutoMode ? LayoutMode.tiled : LayoutMode.auto));
  };

  const tooltipText = isAutoMode
    ? t('topbar.switch_to_custom_mode')
    : t('topbar.switch_to_auto_mode');

  const buttonText = isAutoMode ? t('topbar.auto_mode') : t('topbar.custom_mode');

  const iconName = isAutoMode ? 'active-speaker-mode' : 'tiled-mode';

  return (
    <TopbarControl
      tooltipText={tooltipText}
      onToggle={handleToggle}
      buttonText={buttonText}
      iconName={iconName}
      data-testid="layout-mode-control"
    />
  );
};

export default LayoutModeControl;
