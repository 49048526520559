import { AbstractE2eeUser } from 'features/e2ee/types';

export function transformToAbstractE2ee<T extends { id: string; e2eePublicKey?: string }>(
  items: T[],
  getRoomId: (item: T) => string = () => ''
): AbstractE2eeUser[] {
  const result: AbstractE2eeUser[] = [];

  for (const item of items) {
    result.push({
      id: item.id,
      roomId: getRoomId(item),
      e2eePublicKey: item.e2eePublicKey!,
    });
  }

  return result;
}
