// Encrypt a file, prepend MIME type and IV (12 bytes), and return the encrypted Blob.
import { encode } from 'features/e2ee/utils/encode';

export const encryptFile = async (
  fileBuffer: ArrayBuffer,
  encryptionKey: CryptoKey,
  mimeType: string
) => {
  // Convert MIME type to binary
  const mimeTypeEncoded = encode(mimeType);
  const mimeLength = mimeTypeEncoded.byteLength;

  // Prepend MIME length, MIME type, and file buffer
  const mimeLengthBuffer = new Uint8Array(2);

  /* eslint-disable no-bitwise */
  mimeLengthBuffer[0] = mimeLength >> 8;
  mimeLengthBuffer[1] = mimeLength & 0xff;
  /* eslint-enable no-bitwise */

  const dataBuffer = new Uint8Array(
    mimeLengthBuffer.byteLength + mimeTypeEncoded.byteLength + fileBuffer.byteLength
  );
  dataBuffer.set(mimeLengthBuffer, 0);
  dataBuffer.set(mimeTypeEncoded, mimeLengthBuffer.byteLength);
  dataBuffer.set(
    new Uint8Array(fileBuffer),
    mimeLengthBuffer.byteLength + mimeTypeEncoded.byteLength
  );

  const iv = window.crypto.getRandomValues(new Uint8Array(12));

  const encryptedData = await window.crypto.subtle.encrypt(
    {
      name: 'AES-GCM',
      iv,
    },
    encryptionKey,
    dataBuffer
  );

  // Combine IV and encrypted data into one buffer (IV + ciphertext)
  const combinedBuffer = new Uint8Array(iv.byteLength + encryptedData.byteLength);

  // Append IV at the beginning
  combinedBuffer.set(iv, 0);

  // Extract the remaining bytes as the ciphertext
  combinedBuffer.set(new Uint8Array(encryptedData), iv.byteLength);

  return new Blob([combinedBuffer]);
};
