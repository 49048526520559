import { ReceiverInitOptions } from 'features/e2ee/types';
import { UserId } from 'features/users/types';
import { AesCm256EncryptionKey } from 'features/e2ee/AesCm256EncryptionKey';
import { logger } from 'utils/logger';

export const maxRatchetAttempts = 100;

export class E2eeReceiver {
  id: UserId;

  roomId: string;

  name?: string;

  publicKey?: CryptoKey;

  sharedSecret?: CryptoKey;

  streamKey?: ArrayBuffer;

  applicationKey?: CryptoKey;

  constructor(id: UserId, roomId: string, options: ReceiverInitOptions) {
    this.id = id;
    this.roomId = roomId;
    this.publicKey = options.publicKey;
    this.sharedSecret = options.sharedSecret;
  }

  setName = (name: string) => {
    this.name = name;
  };

  setStreamKey = (key: ArrayBuffer) => {
    this.streamKey = key;
  };

  setApplicationKey = async (raw: ArrayBuffer) => {
    this.applicationKey = await window.crypto.subtle.importKey(
      'raw',
      raw,
      { name: 'AES-GCM' },
      true,
      ['encrypt', 'decrypt']
    );
  };

  ratchetApplicationKey = async () => {
    if (!this.applicationKey) {
      throw new Error('Cannot ratchet the application key. Key in not defined');
    }

    const exportedKey = await crypto.subtle.exportKey('raw', this.applicationKey);

    // @TODO do we need to convert keys into AesCm256EncryptionKey initially?
    const key = await AesCm256EncryptionKey.create(exportedKey);
    const ratchetKey = await key.ratchet();

    logger
      .remote({
        system: true,
        capture: 'e2ee',
      })
      .info('Receiver application key has been ratcheted successfully');

    this.applicationKey = ratchetKey;

    return ratchetKey;
  };
}
