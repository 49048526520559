import { AesCm256EncryptionKey } from 'features/e2ee/AesCm256EncryptionKey';
import { call, select } from 'redux-saga/effects';
import { selectRoomId } from 'features/room/roomSlice';
import { logger } from 'utils/logger';
import { decryptWBRecords } from 'utils/whiteboard/decryptWBRecords';
import { RemoteWBUpdatePayload } from 'features/layout/features/content/types';
import { waitForWhiteboardKeyDecryptionSaga } from 'features/layout/sagas/waitForTheWhiteboardKeyDecryptionSaga';
import { TLRecord } from '@digitalsamba/tldraw';

export function* decryptWhiteboardRemoteUpdatesSaga(data: RemoteWBUpdatePayload) {
  const roomId: string = yield select(selectRoomId);
  const { id } = data;

  const whiteboardKey: AesCm256EncryptionKey = yield call(
    waitForWhiteboardKeyDecryptionSaga,
    id,
    roomId
  );

  const decryptionKey = whiteboardKey.encryptionKey;
  if (!decryptionKey) {
    const errorMessage = `Cannot decrypt whiteboard updates for id=${id}, roomId=${whiteboardKey}. Decryption key is undefined.`;

    return new Error(errorMessage);
  }

  try {
    const encryptedRecords = data.changed.map((record) => record.data);
    const changed: TLRecord[] = yield call(decryptWBRecords, encryptedRecords, decryptionKey);

    return {
      changed,
      removed: data.removed,
    };
  } catch (error) {
    const errorMessage = `Cannot decrypt whiteboard updates for id=${id}, roomId=${roomId}`;
    logger.remote({ system: true, capture: 'e2ee' }).error(errorMessage);

    throw error;
  }
}
