import { Divider } from '@mui/material';
import cx from 'clsx';
import Menu from 'components/Menu/Menu';
import { customControlTriggered } from 'features/layout/actions';
import { RoomLayoutValuesContext } from 'features/layout/Context';
import { selectCustomControls } from 'features/layout/features/config/configSlice';
import { SourceDetails } from 'features/layout/types';
import { useExpandControl } from 'features/streaming/components/controls/useExpandControl';
import { useMinimizeLocalTileControl } from 'features/streaming/components/controls/useMinimizeLocalTileControl';
import { usePinControl } from 'features/streaming/components/controls/usePinControl';
import { useRemoveUserControl } from 'features/streaming/components/controls/useRemoveUserControl';
import { StreamControlButton } from 'features/streaming/components/StreamControlButton';
import { StreamControlMenuItem } from 'features/streaming/components/StreamControlMenuItem';
import React, { useContext, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const TileControls = ({
  source,
  collapse,
}: {
  source: SourceDetails;
  collapse: boolean;
}) => {
  const dispatch = useAppDispatch();
  const [menuOpen, setMenuOpen] = useState(false);

  const { roomWidth, roomHeight } = useContext(RoomLayoutValuesContext);
  const customControls = useAppSelector((state) => selectCustomControls(state, source.kind));
  const closeMenu = () => setMenuOpen(false);

  const { show: showPin, ...pinControl } = usePinControl(source, closeMenu);
  const { show: showExpand, ...expandControl } = useExpandControl(source, closeMenu);
  const { show: showMinimizeLocal, ...minimizeLocalTileControl } = useMinimizeLocalTileControl(
    source,
    closeMenu
  );

  const { show: showRemoveUser, ...removeUserControl } = useRemoveUserControl(source, closeMenu);

  const controls: any[] = [];
  const Control = collapse ? StreamControlMenuItem : StreamControlButton;

  if (showPin) {
    controls.push({ key: 'pin', ...pinControl });
  }

  if (showExpand) {
    controls.push({ key: 'fullscreen', ...expandControl });
  }

  if (showMinimizeLocal) {
    controls.push({ key: 'minimize', ...minimizeLocalTileControl });
  }

  if (showRemoveUser) {
    controls.push({ key: 'remove', ...removeUserControl });
  }

  if (collapse && controls.length && Object.values(customControls).length) {
    controls.push('divider');
  }

  // eslint-disable-next-line guard-for-in
  for (const controlName in customControls) {
    const control = customControls[controlName];

    controls.push({
      key: `customAction-${controlName}`,
      onClick: () => dispatch(customControlTriggered({ action: controlName, source })),
      icon: control.properties.icon || '',
      text: control.properties.label,
    });
  }

  const haveControls = controls.length;

  // TODO: this will cause some issues in the future.
  // there's no good way to handle it now. let's add more confusing behaviors
  useEffect(() => {
    closeMenu();
  }, [roomWidth, roomHeight]);

  const renderControls = () =>
    controls.map((config) => {
      if (config === 'divider') {
        return <Divider />;
      }
      const { key, ...rest } = config;

      return <Control key={key} {...rest} />;
    });

  const renderCollapsedMenu = () =>
    haveControls ? (
      <Menu
        id={`tile-controls-${source.userId}`}
        open={menuOpen}
        onClose={closeMenu}
        placement="top-start"
        trigger={
          <StreamControlButton
            icon="settings1"
            onClick={() => setMenuOpen((open) => !open)}
            className={cx({ 'hover-child': !menuOpen })}
          />
        }
        PaperProps={{
          sx: (theme) => ({
            backgroundColor: theme.palette.grey['900'],
          }),
        }}
        sx={(theme) => ({
          color: theme.palette.common.white,
        })}
      >
        {renderControls()}
      </Menu>
    ) : null;

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return collapse ? renderCollapsedMenu() : <>{renderControls()}</>;
};
