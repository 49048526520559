import { AppThunk } from 'store/store';
import { selectNotificationsByUser } from 'features/notifications/notificationsSlice';
import { UserLeftPayload } from 'features/users/types';
import { userLeft } from 'features/users/usersSlice';
import { closeActionNotifications } from 'features/notifications/thunks/closeActionNotifications';
import { NotificationId } from 'features/notifications/types';

export const cleanupUsers =
  (usersIds: UserLeftPayload[]): AppThunk<void> =>
  async (dispatch, getState) => {
    const userIds: UserLeftPayload[] = [];
    const allNotifications: NotificationId[] = [];

    for (const { userId, roomId } of usersIds) {
      userIds.push({ userId, roomId });
      const notifications = selectNotificationsByUser(getState(), userId);
      allNotifications.push(...notifications);
    }

    dispatch(closeActionNotifications(allNotifications));
    dispatch(userLeft(userIds));
  };
