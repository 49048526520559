import { selectSDKInitState } from 'features/sdk/sdkStateSlice';
import { call, put, select } from 'redux-saga/effects';
import i18n, { criticalNamespaces } from 'i18n';
import { eventBus, SDKInitState } from 'utils/eventBus';
import { SupportedLanguages } from 'features/application/types';
import { supportedLanguagesLoaded } from 'features/application/applicationSlice';

const fallbackMapping: Record<string, string> = {
  de: 'df',
  'de-DE': 'df',
  es: 'es-ES',
};

export function* loadLocalizationSaga(
  dashboardLanguage: string | undefined,
  supportedLanguages?: SupportedLanguages
) {
  const initState: SDKInitState = yield select(selectSDKInitState);

  let language: string | undefined = initState.appLanguage;

  if (fallbackMapping[language]) {
    language = fallbackMapping[language];
  }

  if (supportedLanguages) {
    yield put(supportedLanguagesLoaded(supportedLanguages));

    const languageExists = supportedLanguages.find((lang) => lang.value === language);

    if (language && !languageExists) {
      const supportedLanguagesString = supportedLanguages.join(',');

      yield call(eventBus.error, {
        name: 'locale',
        message: `Requested language (${language}) is not supported, falling back to default. Supported languages are: ${supportedLanguagesString}`,
      });

      language = undefined;
    }
  }

  if (!language) {
    language = dashboardLanguage || 'en';
  }

  yield call(i18n.changeLanguage, language);
  yield call(i18n.loadNamespaces, criticalNamespaces);
}
