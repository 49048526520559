import { selectPreserveAspectRatio } from 'features/streaming/streamingSlice';
import { call, put, select, take } from 'redux-saga/effects';
import { signalingConnected } from 'features/application/actions';
import { SignalingSocket } from 'services/signaling';
import { PayloadAction } from '@reduxjs/toolkit';
import { signalingRoomJoined } from 'features/room/actions';
import { establishJanusConnectionSaga } from 'features/room/sagas/establishJanusConnectionSaga';
import { FeedMediaStates, RTCClient } from 'utils/webrtc';
import * as Sentry from '@sentry/react';
import { roomError, selectAwaitingRoomJoin, selectRoomStatus } from 'features/room/roomSlice';
import { JoinRoomData, RoomJoinedPayload, RoomStatus } from 'features/room/types';
import { selectE2eeEnabled } from 'features/e2ee/e2eeSlice';
import { E2EEManager } from 'features/e2ee/E2EEManager';
import { waitingEntryNotificationUpdateRequested } from 'features/notifications/actions';
import { setupE2eeSaga } from 'features/e2ee/sagas/setupE2eeSaga';

export function* reconnectionSaga() {
  try {
    yield take(signalingConnected);

    const e2eeEnabled: boolean = yield select(selectE2eeEnabled);

    const joinPayload: JoinRoomData = {};

    if (e2eeEnabled) {
      joinPayload.e2eePublicKey = yield call(E2EEManager.exportPublicKey);
    }

    const preserveAspectRatio: boolean = yield select(selectPreserveAspectRatio);

    if (preserveAspectRatio) {
      joinPayload.keepAspectRatio = preserveAspectRatio;
    }

    const awaitingRoomJoin: boolean = yield select(selectAwaitingRoomJoin);
    if (awaitingRoomJoin) {
      // only send 'joinRoom' event when waiting for the session to start on the join screen
      yield call(SignalingSocket.send, { event: 'joinRoom', data: joinPayload });
      return;
    }

    const roomStatus: RoomStatus = yield select(selectRoomStatus);
    if (roomStatus !== 'joined') {
      // skip the rest of interactions outside the room
      return;
    }

    const mediaStates: FeedMediaStates = yield call(RTCClient.publishingFeed.getMediaStates);

    yield call(SignalingSocket.send, { event: 'joinRoom', data: joinPayload });
    const { payload: initData }: PayloadAction<RoomJoinedPayload> = yield take(signalingRoomJoined);

    if (e2eeEnabled) {
      yield call(setupE2eeSaga, initData);
    }

    yield call(establishJanusConnectionSaga, initData, {
      audio: mediaStates.audio.broadcasting,
      video: mediaStates.video.broadcasting,
    });

    yield put(waitingEntryNotificationUpdateRequested());
  } catch (error) {
    yield call(Sentry.captureException, error);
    yield put(roomError({ global: true }));
  }
}
