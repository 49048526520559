import { call, select } from 'redux-saga/effects';
import { signalingRecorderJoined } from 'features/recorder/actions';
import { selectE2eeEnabled } from 'features/e2ee/e2eeSlice';
import { exchangeEncryptionKeysSaga } from 'features/e2ee/sagas/exchangeEncryptionKeysSaga';
import { selectRoomId } from 'features/room/roomSlice';

export function* onRecorderJoinedSaga(action: ReturnType<typeof signalingRecorderJoined>) {
  const e2eeEnabled: boolean = yield select(selectE2eeEnabled);
  const roomId: string = yield select(selectRoomId);

  if (e2eeEnabled) {
    yield call(exchangeEncryptionKeysSaga, {
      id: action.payload.id,
      roomId,
      e2eePublicKey: action.payload.e2eePublicKey,
    });
  }
}
