import { signalingWhiteboardChanged } from 'features/layout/features/content/actions';
import { call, select } from 'redux-saga/effects';
import { board, WBRemoteUpdateData } from 'utils/whiteboard/BoardStateManager';
import { selectE2eeEnabled } from 'features/e2ee/e2eeSlice';
import { decryptWhiteboardRemoteUpdatesSaga } from 'features/layout/sagas/decryptWhiteboardRemoteUpdatesSaga';
import * as Sentry from '@sentry/react';

export function* onWhiteboardChangedSaga(action: ReturnType<typeof signalingWhiteboardChanged>) {
  const e2eeEnabled: boolean = yield select(selectE2eeEnabled);

  let data: WBRemoteUpdateData = {
    changed: [],
    removed: [],
  };

  try {
    if (e2eeEnabled) {
      data = yield call(decryptWhiteboardRemoteUpdatesSaga, action.payload);
    } else {
      data.removed = action.payload.removed;
      data.changed = action.payload.changed.map((row: any) => JSON.parse(row.data));
    }

    yield call(board.acceptRemoteChanges, data);
  } catch (error) {
    Sentry.captureException(error);
  }
}
