import { call } from 'redux-saga/effects';
import { E2EEManager } from 'features/e2ee/E2EEManager';
import { e2eeEncrypt } from 'features/e2ee/utils/e2eeEncrypt';
import { SignalingSocket } from 'services/signaling';
import { AesCm256EncryptionKey } from 'features/e2ee/AesCm256EncryptionKey';
import { AbstractE2eeUser } from 'features/e2ee/types';

export function* exchangeWhiteboardKeySaga(whiteboardId: string, user: AbstractE2eeUser) {
  const sharedSecret: CryptoKey = yield call(
    E2EEManager.exchangeEncryptionKey,
    user.id,
    user.roomId,
    user.e2eePublicKey
  );

  const whiteboardKey: AesCm256EncryptionKey | undefined = yield call(
    E2EEManager.whiteboard.getKey,
    whiteboardId,
    user.roomId
  );

  if (!whiteboardKey) {
    throw new Error(
      `Cannot exchange a whiteboard key for whiteboardId=${whiteboardId}, roomId=${user.roomId}. Key is not defined`
    );
  }

  const exportedWhiteboardKey: string = yield call(whiteboardKey.export);
  const encryptedStreamKey: string = yield call(e2eeEncrypt, exportedWhiteboardKey, sharedSecret);

  yield call(SignalingSocket.send, {
    event: 'shareWhiteboardKey',
    data: {
      id: whiteboardId,
      key: encryptedStreamKey,
      userId: user.id,
    },
  });
}
